import React from 'react';
import {
  HomeOutlined,
  //SettingOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined,
  ShoppingOutlined,
  PrinterOutlined,
  RollbackOutlined,
  LayoutOutlined,
  ProfileOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { SMenu } from '.';
import { ButtonIcon } from 'components/common';
import POSVG from 'assets/icons/po';
import PrepackSVG from 'assets/icons/prepack';
import WarehouseTransferSVG from 'assets/icons/warehouseTransfer';
// import { isTestEnv } from  'utils'

const pageExitMessage: StringKVPair = {
  '/administration/warehouse': 'Do you want to exit warehouse page?',
  // '/inventory/assemblekits': 'Changes you made may NOT be saved?',
};

const routeAlias: StringKVPair = {
  '/administration/zones': '/administration/zone-management',
};

const menuTree: SMenu[] = [
  {
    icon: <HomeOutlined className="sidebar-menu-icon" />,
    name: 'Dashboard',
    route: '/',
  },
  {
    icon: <ButtonIcon className="bi bi-box sidebar-menu-icon" style={{ marginRight: 0 }} />,
    name: 'Inventory',
    items: [
      {
        name: 'Search',
        route: '/inventory/search',
      },
      {
        name: 'Check Out',
        route: '/inventory/checkout',
      },
      {
        name: 'Check In',
        route: '/inventory/checkin',
      },
      {
        name: 'Receive',
        route: '/inventory/receive',
      },
      {
        name: 'Move/Merge',
        route: '/inventory/movemerge',
      },
      {
        name: 'Audit',
        route: '/inventory/audit',
      },
      {
        name: 'Audit Batch',
        route: '/inventory/audit-batch',
      },
      {
        name: 'Cycle Counting',
        route: '/inventory/cycle-counting',
      },
      // {
      //   name: 'Assemble Kits',
      //   route: '/inventory/assemblekits',
      // },
    ],
  },
  {
    // icon: <ShoppingOutlined  />,
    icon: <LayoutOutlined className="sidebar-menu-icon" />,
    name: 'Catalog',
    items: [
      {
        name: 'View Catalog',
        route: '/catalog/view',
      },
      {
        name: 'LOT Management',
        route: '/catalog/lot-Management'
      }
      // {
      //   name: 'Lots',
      //   route: '/catalog/lots',
      // },
      // {
      //   name: 'Create Kit',
      //   route: '/catalog/create-kit',
      // },
    ],
  },
  {
    icon: <ShoppingOutlined className="sidebar-menu-icon" />,
    name: 'Sales Order',
    items: [
      {
        name: 'Queue Pick List',
        route: '/sales/generate-new-pick-list',
      },
      {
        name: 'Print Pick List',
        route: '/sales/print-generated-picklists',
      },
      {
        name: 'Scan to Ship',
        route: '/sales/scan-verify-print',
      },
      {
        name: 'View Orders',
        route: '/sales/view-orders',
      },
      {
        name: 'Shipments',
        route: '/sales/shipments',
      },
      {
        name: 'Consolidate Packages',
        route: '/sales/small-packages-to-container',
      },
      {
        name: 'Order White List',
        route: '/sales/white-list',
      },
      {
        name: 'Smart',
        route: '/smart',
      },
    ],
  },
  {
    icon: <RollbackOutlined className="sidebar-menu-icon" />,
    name: 'Return',
    items: [
      {
        name: 'RMA List',
        route: '/return/list',
      },
      {
        name: 'RMA Receive',
        route: '/return/receive',
      },
      {
        name: 'RMA Restock',
        route: '/return/restock',
      },
      {
        name: 'Customer Return (Bulk Return)',
        route: '/return/bulk-return',
      },
    ],
  },
  {
    icon: <POSVG className="sidebar-menu-icon" />,
    name: 'PO',
    items: [
      {
        name: 'PO List',
        route: '/po/list',
      },
      {
        name: 'PO Receive',
        route: '/po/receive',
      },
      {
        name: 'PO Receive Batch',
        route: '/po/poReceiveBatch',
      },
    ],
  },
  {
    icon: <PrepackSVG className="sidebar-menu-icon" />,
    name: 'Pre-Pack',
    route: '/pre-pack',
    items: [
      {
        name: 'Pack',
        route: '/pre-pack/pack',
      },
      {
        name: 'Unpack',
        route: '/pre-pack/unpack',
      },
    ],
  },
  {
    icon: <WarehouseTransferSVG className="sidebar-menu-icon" />,
    name: 'Warehouse Transfer',
    items: [
      {
        name: 'List',
        route: '/warehouse-transfer/list',
      },
      {
        name: 'Receive',
        route: '/warehouse-transfer/receive',
      }
    ],
  },
  {
    icon: <ProfileOutlined className="sidebar-menu-icon" />,
    name: 'Report',
    items: [
      {
        name: 'Transaction Log',
        route: '/reports/transaction-log',
      },
      {
        name: 'Locations Report',
        route: '/reports/locations-report',
      },
      {
        name: 'Employee Shipment',
        route: '/reports/employee-shipment',
      },
      {
        name: 'Receiving History',
        route: '/reports/receiving-history',
      },
      {
        name: 'Special Inventory',
        route: '/reports/special-inventory',
      },
      {
        name: 'Audit Batch Close History',
        route: '/reports/audit-close/history',
      },
      {
        name: 'PO Receive Batch History',
        route: '/reports/po-receive-batch/history',
      },
      {
        name: 'Reprint History',
        route: '/reports/reprint/history',
      },
      {
        name: 'Order White List History',
        route: '/reports/white-list/history'
      },
      {
        name: 'Shipment Void History',
        route: '/reports/shipment-void/history'
      },
      {
        name: 'Package Material Summary',
        route: '/reports/package-material-summary/history'
      },
    ],
  },
  {
    icon: <PrinterOutlined className="sidebar-menu-icon" />,
    name: 'Print',
    items: [
      {
        name: 'Print Location Labels',
        route: '/print/location-labels',
      },
    ],
  },
  {
    icon: <CloudUploadOutlined className="sidebar-menu-icon" />,
    name: 'Import',
    route: '/import',
  },
  {
    icon: <CloudDownloadOutlined className="sidebar-menu-icon" />,
    name: 'Export',
    route: '/export',
  },
  {
    icon: <SettingOutlined className="sidebar-menu-icon" />,
    name: 'Administration',
    items: [
      {
        name: 'Warehouse Assignments',
        route: '/administration/accounts',
      },
      {
        name: 'Warehouse',
        items: [
          {
            name: 'Warehouse List',
            route: '/administration/warehouse'
          },
          {
            name: 'Zone Management',
            route: '/administration/zone-management',
          },
          {
            name: 'Location Aspects',
            route: '/administration/aspect-management',
          },
          {
            name: 'Location Management',
            route: '/administration/location-management',
          }
        ]
      },
      // {
      //   name: 'Brands',
      //   route: '/administration/brands',
      // },
      {
        name: 'Transaction Reasons',
        route: '/administration/transationReasons',
      },
      // {
      //   name: 'Product Tag Management',
      //   route: '/administration/productTag',
      // },
      // {
      //   name: 'Order Tag',
      //   route: '/administration/orderTag',
      // },
      {
        name: 'Store Account',
        route: '/administration/channel-account',
      },
      // {
      //   name: 'Warehouse Routing Rule',
      //   route: '/administration/route-rule',
      // },
      {
        name: 'Address Book',
        route: '/administration/address-book',
      },
      {
        name: 'Shipping',
        items: [
          {
            name: 'Shipping Account',
            route: '/shipment/shipping-account',
          },
          {
            name: 'Shopping Around',
            route: '/shipment/shopping-around',
          },
          {
            name: 'Custom Packages',
            route: '/shipment/custom-packages',
          },
          {
            name: 'Package Selection',
            route: '/shipment/package-selection',
          },
          {
            name: 'Shipping Priority',
            route: '/shipment/shipping-priority',
          },
          {
            name: 'Shipping Provider',
            route: '/shipment/shipping-provider-account'
          },
          {
            name: 'Channel Fulfillment',
            route: '/shipment/shipping-channel-fulfillment'
          }
        ],
      },
      {
        name: 'Profile Settings',
        route: '/administration/user-profile-settings',
      },
      {
        name: 'LOT',
        route: '/administration/lot-settings',
      },
      {
        name: 'Prepack',
        route: '/administration/prepack-settings',
      },
      {
        name: 'PrintStation Managment',
        route: '/administration/printStation-managment',
      },
    ],
  },
  {
    // icon: <FontAwesomeIcon className="sidebar-menu-icon" icon={faHandshake as IconProp} style={{ width: 13 }} />,
    //icon: <i className="bi-truck" style={{width: 13}} />,
    icon: <i className="bi bi-boxes sidebar-menu-icon" />,
    name: 'Session',
    items: [
      {
        name: 'Settings',
        route: '/administration/settings',
      },
    ],
  },
  /*{
    icon: <FontAwesomeIcon icon={faTruck} style={{ width: 13 }} />,
    //icon: <i className="bi-truck" style={{width: 13}} />,
    name: 'Shipment Settings',
    items: [
      {
        name: 'Shipping Account',
        route: '/shipment/shipping-account',
      },
      {
        name: 'Packages',
        route: '/shipment/packages',
      },
    ],
  },*/
];

export const defaultExitConfirmMessage =
  'You are about to go to a new page, current page is in editing or in working, are you sure?';

export const exitConfirmMessage = pageExitMessage;

export const getMenuPath = (route: string): string => {
  return routeAlias[route] || route;
};

export default menuTree;
