import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
  Row,
  Card,
  Form,
  Col,
  DatePicker,
  Space,
  Button,
  Input,
  Select,
  Menu,
  Dropdown,
  message,
  Modal,
  Tooltip,
  Typography,
} from 'antd';
import {
  ClearOutlined,
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
  SettingFilled,
  CaretDownOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {
  ColumnDataType,
  DataGridPlus,
  FormLabel,
  HeaderTitle,
  TablesFilter,
  CopySpan,
  SpaceCell,
  CopyComponent,
} from 'components/common';
import { fetchProductLotList, deleteProductLot } from 'services/lot';
import { getProfileSettingValue } from 'utils';
import EditDialog from './EditDialog';
import AddDialog from './AddDialog';
import Text from 'antd/lib/typography/Text';
import {
  DEFAULT_US_DATE_FORMAT,
  DATAGRID_CELL_ICON_WIDTH1,
} from 'constants/config';
import copy from 'copy-text-to-clipboard';
import theme from 'assets/styles/theme';
import ProductDetail from 'components/Inventory/ProductDetail/ProductDetailsDialog';

const StatusEnum: { [key: string]: string } = {
  '1': 'Active',
  '0': 'InActive',
};

// eslint-disable-next-line
export default () => {
  const [loading, setLoading] = useState(false);
  const [lotList, setLotList] = useState<ProductLot[]>([]);
  const [count, setCount] = useState<number>(0);
  const [tempParams, setTempParams] = useState<any>();
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [current, setCurrent] = useState<any>();
  const [editDialogVisible, setEditDialogVisible] = useState(false);
  const [addDialogVisible, setAddDialogVisible] = useState(false);
  const [timeZone, setTimeZone] = useState<string>('');
  const [detailVisible, setDetailVisible] = React.useState(false);

  const limitRef = useRef(10);
  const skipRef = useRef(0);

  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;

  const handleSearch = useCallback(async () => {
    if (skipRef.current !== 0) {
      skipRef.current = 0;
    }
    const temp = await form.getFieldsValue();
    const params = {
      ...temp,
      firstInDate: temp.firstInDate
        ? { from: temp.firstInDate[0], to: temp.firstInDate[1] }
        : undefined,
      expirationDate: temp.expirationDate
        ? { from: temp.expirationDate[0], to: temp.expirationDate[1] }
        : undefined,
      manufactionDate: temp.manufactionDate
        ? { from: temp.manufactionDate[0], to: temp.manufactionDate[1] }
        : undefined,
    };
    try {
      setLoading(true);
      const res = await fetchProductLotList(params, {
        pageIndex: 1,
        pageSize: limitRef.current,
      });
      setLoading(false);
      if (res.isSuccess) {
        setLotList(res.data);
        setCount(res.total);
        setTempParams(params);
      }
    } catch (error) {
      setLoading(false);
    }
  }, [skipRef, limitRef, form]);

  const handleDataGridChange = useCallback(
    async (limit: number, skip: number) => {
      if (!tempParams) return;
      try {
        setLoading(true);
        const res = await fetchProductLotList(tempParams, {
          pageIndex: skip / limit + 1,
          pageSize: limit,
        });
        setLoading(false);
        if (res.isSuccess) {
          setLotList(res.data);
          setCount(res.total);
          limitRef.current = limit;
          skipRef.current = skip;
        }
      } catch (error) {
        setLoading(false);
      }
    },
    [skipRef, limitRef, tempParams]
  );

  const delLot = useCallback(
    async (lot: ProductLot) => {
      try {
        const res = await deleteProductLot(lot);
        if (res) {
          message.success('delete successfully');
          handleSearch();
        }
      } catch (error) {}
    },
    [handleSearch]
  );

  const onClickSkuCell = (data: ProductRow) => {
    const selObj = window.getSelection();
    let open = selObj && selObj.toString() ? false : true;

    if (open) {
      console.log(data);
      setCurrent(data);
      setDetailVisible(true);
    }
  };

  const columns = [
    {
      name: 'sku',
      header: 'SKU',
      minWidth: 220,
      defaultFlex: 1,
      userSelect: true,
      render: (value: any) => {
        const { data, rowIndex } = value;

        return (
          <SpaceCell
            id={`catalog_grid_product_sku_row_${rowIndex}`}
            icon={
              <Tooltip
                placement="top"
                title="Copy"
                trigger={['hover', 'click']}
              >
                <CopyOutlined
                  onClick={() => {
                    copy(data.sku);
                    message.info(`"${data.sku}" has been copied to clipboard`);
                  }}
                  style={{ color: theme['@info-color'] }}
                />
              </Tooltip>
            }
            iconWidth={DATAGRID_CELL_ICON_WIDTH1}
            onTextClick={() => onClickSkuCell(data)}
            text={data.sku}
            textIsButton
          />
        );
      },
    },
    {
      name: 'upc',
      header: 'UPC',
      minWidth: 150,
      defaultFlex: 1,
      userSelect: true,
    },
    {
      name: 'lotNumber',
      header: 'LOT#',
      minWidth: 150,
      defaultFlex: 1,
      userSelect: true,
      render: (value: any) => {
        const { data } = value;
        return (
          <CopyComponent value={data.lotNumber}>
            <Typography.Link
              onClick={() => {
                setCurrent(data);
                setEditDialogVisible(true);
              }}
            >
              {data.lotNumber}
            </Typography.Link>
          </CopyComponent>
        );
      },
    },
    {
      name: 'status',
      header: 'Status',
      userSelect: true,
      defaultFlex: 1,
      renderEnum: StatusEnum,
      render: (value: any) => {
        const { data } = value;
        return <CopySpan inGrid value={StatusEnum[`${data.status}`]} />;
      },
    },
    {
      name: 'expirationDate',
      header: 'Expiration Date',
      userSelect: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      format: DEFAULT_US_DATE_FORMAT,
    },
    {
      name: 'firstInDate',
      header: 'First In Date',
      userSelect: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      format: DEFAULT_US_DATE_FORMAT,
    },
    {
      name: 'manufactionDate',
      header: 'Manufaction Date',
      userSelect: true,
      defaultFlex: 1,
      dataType: ColumnDataType.DATE,
      format: DEFAULT_US_DATE_FORMAT,
    },
    {
      name: 'supplierLot',
      header: 'Supplier Lot',
      userSelect: true,
      defaultFlex: 1,
    },
    {
      header: 'Action',
      name: 'action',
      //@ts-ignore
      showColumnMenuTool: false,
      defaultLocked: 'end',
      sortable: false,
      minWidth: 100,
      maxWidth: 100,
      defaultFlex: 1,
      render: (value: any) => {
        const { data, rowIndex } = value;
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1">
                  <Button
                    id="lot_eidt"
                    type="text"
                    style={{ width: '100%', textAlign: 'left' }}
                    icon={<EditOutlined />}
                    onClick={() => {
                      setCurrent(data);
                      setEditDialogVisible(true);
                    }}
                  >
                    Edit
                  </Button>
                </Menu.Item>
                <Menu.Item key="2">
                  <Button
                    id="lot_delete"
                    type="text"
                    className="hover-danger-button-2"
                    style={{ width: '100%', textAlign: 'left' }}
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      Modal.confirm({
                        title: 'Are you sure you want delete this LOT#?',
                        icon: <ExclamationCircleOutlined />,
                        content: (
                          <Space direction="vertical" size="small">
                            <Text>{`SKU: ${data.sku}`}</Text>
                            <Text>{`LOT#: ${data.lotNumber}`}</Text>
                          </Space>
                        ),
                        okText: 'Yes',
                        okType: 'default',
                        okButtonProps: { className: 'hover-danger-button' },
                        cancelText: 'No',
                        autoFocusButton: 'cancel',
                        onOk() {
                          delLot(data);
                        },
                        onCancel() {},
                      });
                    }}
                  >
                    Delete
                  </Button>
                </Menu.Item>
              </Menu>
            }
            trigger={['click']}
          >
            <Button id={`lot_${rowIndex}_dropdown`} icon={<SettingFilled />}>
              <CaretDownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const getSetting = useCallback(async () => {
    const res3 = await getProfileSettingValue('TimeZone');
    setTimeZone(res3);
  }, []);

  useEffect(() => {
    getSetting();
  }, [getSetting]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 9 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 15 },
    },
  };

  return (
    <>
      <HeaderTitle breadcrumb={['Catalog', 'LOT Management']}>
        <Space>
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setAddDialogVisible(true)}
          >
            Add LOT#
          </Button>
        </Space>
      </HeaderTitle>
      <Row justify="center">
        <Card style={{ width: '100%', minWidth: 400 }}>
          <Form
            form={form}
            {...formItemLayout}
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <Row>
              <Col lg={8} sm={24} md={12}>
                <Form.Item name="sku" label={<FormLabel>SKU</FormLabel>}>
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col lg={8} sm={24} md={12}>
                <Form.Item name="status" label={<FormLabel>Status</FormLabel>}>
                  <Select defaultValue={1}>
                    <Select.Option value={null}>All</Select.Option>
                    <Select.Option value={1}>Active</Select.Option>
                    <Select.Option value={0}>Inactive</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={8} sm={24} md={12}>
                <Form.Item name="lot" label={<FormLabel>LOT#</FormLabel>}>
                  <Input allowClear />
                </Form.Item>
              </Col>
              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="firstInDate"
                  label={<FormLabel>First In Date</FormLabel>}
                >
                  <RangePicker
                    allowClear
                    style={{ width: '100%' }}
                    format="MM/DD/YYYY"
                    ranges={{
                      Today: [moment().startOf('day'), moment().endOf('day')],
                      Yesterday: [
                        moment().add(-1, 'day').startOf('day'),
                        moment().add(-1, 'day').endOf('day'),
                      ],
                      '2 days ago': [
                        moment().add(-2, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      '3 days ago': [
                        moment().add(-3, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      'This Week': [
                        moment().startOf('week'),
                        moment().endOf('week'),
                      ],
                      'Last Week': [
                        moment().add(-1, 'week').startOf('week'),
                        moment().add(-1, 'week').endOf('week'),
                      ],
                      'This Month': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="expirationDate"
                  label={<FormLabel>Expiration Date</FormLabel>}
                >
                  <RangePicker
                    allowClear
                    style={{ width: '100%' }}
                    format="MM/DD/YYYY"
                    ranges={{
                      Today: [moment().startOf('day'), moment().endOf('day')],
                      Yesterday: [
                        moment().add(-1, 'day').startOf('day'),
                        moment().add(-1, 'day').endOf('day'),
                      ],
                      '2 days ago': [
                        moment().add(-2, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      '3 days ago': [
                        moment().add(-3, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      'This Week': [
                        moment().startOf('week'),
                        moment().endOf('week'),
                      ],
                      'Last Week': [
                        moment().add(-1, 'week').startOf('week'),
                        moment().add(-1, 'week').endOf('week'),
                      ],
                      'This Month': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="manufactionDate"
                  label={<FormLabel>Manufaction Date</FormLabel>}
                >
                  <RangePicker
                    allowClear
                    style={{ width: '100%' }}
                    format="MM/DD/YYYY"
                    ranges={{
                      Today: [moment().startOf('day'), moment().endOf('day')],
                      Yesterday: [
                        moment().add(-1, 'day').startOf('day'),
                        moment().add(-1, 'day').endOf('day'),
                      ],
                      '2 days ago': [
                        moment().add(-2, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      '3 days ago': [
                        moment().add(-3, 'day').startOf('day'),
                        moment().endOf('day'),
                      ],
                      'This Week': [
                        moment().startOf('week'),
                        moment().endOf('week'),
                      ],
                      'Last Week': [
                        moment().add(-1, 'week').startOf('week'),
                        moment().add(-1, 'week').endOf('week'),
                      ],
                      'This Month': [
                        moment().startOf('month'),
                        moment().endOf('month'),
                      ],
                    }}
                  />
                </Form.Item>
              </Col>
              <Col lg={8} sm={24} md={12}>
                <Form.Item
                  name="supplierLot"
                  label={<FormLabel>Supplier Lot</FormLabel>}
                >
                  <Input allowClear />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row justify="center">
            <Space>
              <Button type="primary" onClick={() => handleSearch()}>
                <SearchOutlined />
                Search
              </Button>
              <Button onClick={() => form.resetFields()}>
                <ClearOutlined />
                Reset
              </Button>
            </Space>
          </Row>
        </Card>
      </Row>
      <Card style={{ marginTop: 20 }}>
        <TablesFilter
          columns={columns}
          dataSource={lotList}
          setFilteredData={setFilteredData}
        />
        <DataGridPlus
          idProperty="productLotNum"
          showScrollButton
          loading={loading}
          columns={columns}
          autoWith={false}
          pageSizes={[10, 20, 50, 100]}
          dataSource={filteredData}
          pagination
          style={{ height: 500 }}
          count={count}
          onChange={(limit, skip) => handleDataGridChange(limit, skip)}
          {...({} as any)}
        />
      </Card>
      {editDialogVisible && current && (
        <EditDialog
          visible
          onClose={() => setEditDialogVisible(false)}
          onSuccess={() => {
            setEditDialogVisible(false);
            handleDataGridChange(limitRef.current, skipRef.current);
          }}
          lotInfo={current}
        />
      )}
      {addDialogVisible && (
        <AddDialog
          visible
          timeZone={timeZone}
          onClose={() => setAddDialogVisible(false)}
          onSuccess={() => {
            setAddDialogVisible(false);
          }}
        />
      )}
      {detailVisible && current && (
        <ProductDetail
          visible={detailVisible}
          onHide={() => setDetailVisible(false)}
          productId={`${current.databaseNum}-${current.fulfillmentProductNum}`}
        />
      )}
    </>
  );
};
