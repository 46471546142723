import React, { useCallback, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { Layout } from 'antd';
import Dashboard from 'screens/Dashboard';
import Products from 'screens/Products';
import CatalogView from 'screens/CatalogView';
//import ContentWindow from 'screens/ContentWindow';
import Fulfill from 'screens/Fulfill';
import InventoryAudit from 'screens/InventoryAudit';
import InventoryAuditBatch from 'screens/AuditBatch';
import InventoryCycleCounting from 'screens/InventoryCycleCounting';
// import InventoryAssembleKits from 'screens/InventoryAssembleKits';
import InventoryReceive from 'screens/InventoryReceive';
import InventoryRemove from 'screens/InventoryRemove';
import AdminAccount from 'screens/AdminAccount';
import InventorySearch from 'screens/InventorySearch';
import ShippingAroundScreen from 'screens/ShippingAroundScreen';
import InventoryAdd from 'screens/InventoryAdd';
import SalesGeneratePickList from 'screens/SalesGeneratePickList';
import SalesViewOrders from 'screens/SalesViewOrders';
import SalesScanToShip from 'screens/SalesScanToShip';
import SalesScanVerifyPrint from 'screens/SalesScanVerifyPrint';
import Warehouse from 'screens/Warehouse';
import ZoneManagement from 'screens/ZoneManagement';
import LocationManagement from 'screens/LocationManagement';
import AspectManagement from 'screens/LocationAspects';
import Zones from 'screens/Zones';
import ZoneLocation from 'screens/ZoneLocation';
import Shipments from 'screens/Shipments';
import Refund from 'screens/Refund';
import CustomService from 'screens/CustomService';
import TransactionHistory from 'screens/TransactionHistory';
import LocationsReport from 'screens/LocationsReport';
import EmployeeShipment from 'screens/EmployeeShipment';
import Import from 'screens/Import';
import Export from 'screens/Export';
import PrintLocationLabel from 'screens/PrintLocationLabel';
// import BrandManagement from 'screens/BrandManage';
import PoList from 'screens/PoList';
import PoDetail from 'screens/PoDetail';
import TransationReason from 'screens/TransationReasons';
// import PoReceive from 'screens/PoReceive';
// import ProductTagManagement from 'screens/ProductTagManagement';
import OrderTagManagement from 'screens/OrderTagManagement';
import ShipmentCustomPackages from 'screens/ShipmentCustomPackages';
import ShipmentPackageSelection from 'screens/ShipmentPackageSelection';
import Carriers from 'screens/Carriers';
import ShipmentsList from 'screens/ShipmentsList';
import PrintGeneratedPicklists from 'screens/PrintGeneratedPicklists';
import { useCache } from 'hooks';
import ReceivingHistory from 'screens/ReceivingHistory';
import InventoryMoveMerge from 'screens/InventoryMoveMerge';
import StoreSetup from 'screens/AdminStoreSetup';
import RouteRule from 'screens/AdminWarehouseRouteRule';
import AddressBook from 'screens/AddressBook';
import Setting from 'screens/AdminSetting';
import SpecialInventory from 'screens/CheckoutInventory';
import SmartSKUPickListScreen from 'screens/SmartSKUPickListScreen';
import SmartSKUPickListScreen2 from 'screens/SmartSKUPickListScreen2';
import SmartOrderPickListScreen from 'screens/SmartOrderPickListScreen';
import SmartOrderPickListScreen2 from 'screens/SmartOrderPickListScreen2';
import SmartPickScreen from 'screens/SmartPickScreen';
import SmartVirtualWarehouseToLocation from 'screens/VirtualWarehouseToLocation';
import SmartReturnToLocation from 'screens/SmartReturnToLocation';
import SmartInspectionToLocation from 'screens/SmartInspectionToLocation';
import SmartReceivingToLocation from 'screens/SmartReceivingToLocation';
import SmartMoveMerge from 'screens/SmartMoveMerge';
import SmartMove from 'screens/SmartMove';
import SmartMove2 from 'screens/SmartMove2';
import SmartMerge from 'screens/SmartMerge';
import SmartGuidedMove from 'screens/SmartGuidedMove';
import SmartGuidedMove2 from 'screens/SmartGuidedMove2';
import SmartAudit from 'screens/SmartAudit';
import SmartCheckItem from 'screens/SmartCheckItem';
import SmartCheckLocation from 'screens/SmartCheckLocation';
import SmartResetInventory from 'screens/SmartResetInventory';
import SmartResetInventory2 from 'screens/SmartResetInventory2';
import SmartScreen from 'screens/SmartScreen';
import SmartMoveWarehouse from 'screens/MoveWarehouse';
import SmartNormalToDamage from 'screens/NormalToDamage';
import SmartReturnDtoDamage from 'screens/ReturnDtoDamage';
import SmartReturnItoReturnD from 'screens/ReturnItoReturnD';
import SmartReturnItoReturnR from 'screens/ReturnItoReturnR';
import SmartReturnRtoReturnD from 'screens/ReturnRtoReturnD';
import SmartReturnRtoDamage from 'screens/ReturnRtoDamage';
import { isSmart, getProfileSettingValue } from 'utils';
import { setUrlPath } from 'actions/regularActions';
import { DEFAULT_SCAN_TO_SHIP_VERSION, SCAN_TO_SHIP_VERSION2, MOBILE_URL_PREFIX } from 'constants/config';
import { RootState } from 'reducers';
import { ThemeProvider } from 'styled-components';
import { DEFAULT_FONT_SIZE } from 'constants/config';
import SideBar from '../Sidebar';
import ListPoReceive from 'screens/ListPOReceive';
import UserProfileSetting from 'screens/UserProfileSetting';
import AuditBatchCloseHistory from 'screens/AuditBatchCloseHistory';
import POBatchReceiveHistory from 'screens/POBatchReceiveHistory';
import ShippingPriority from 'screens/ShippingPriority';
import SmartConfirm from 'screens/SmartConfirmOrderShipment';
import SmartConfirmTracking from 'screens/SmartConfirmByTracking';
import DelTestData from 'screens/CleanTestData';
import SmallPackagesToContainer from 'screens/SmallPackagesToContainer';
import ReprintHistory from 'screens/ReprintHistory';
import WarehouseTransfer from 'screens/WarehouseTransfer';
import WarehouseTransferReceive from 'screens/WarehouseTransferReceive';
import ReturnList from 'screens/ReturnList';
import ReturnReceive from 'screens/ReturnReceive';
import ReturnStock from 'screens/ReturnStock';
import BulkReturn from 'screens/BulkReturn';
import WhiteList from 'screens/WhiteList';
import WhiteListHistory from 'screens/WhiteListHistory';
import ShipmentVoidHistory from 'screens/ShipmentVoidHistory';
import ProviderAccount from 'screens/ShippingProviderAccount';
import PrinterManage from 'screens/AdminPrinterManage';
import SmartMoveAllProducts from 'screens/SmartMoveAllProduct';
import Pack from 'screens/PackPrePack';
import Unpack from 'screens/UnpackPrePack'
import PrepackSetting from 'screens/PrepackSetting';
import PoReceive2 from 'screens/PoReceive2'
import SmartPrepack from 'screens/SmartPrepack';
import SmartPickForPack from 'screens/SmartPickForPack';
import LOTSetting from 'screens/LOTSetting';
import SmartMultipleMove from 'screens/SmartMultipleMove';
import SmartUnpack from 'screens/SmartUnpack';
import SmartUnpackItem from 'screens/SmartUnpackItem';
import SmartCheckProgress from 'screens/SmartCheckPackProgress';
import LotNumberManage from 'screens/LotNumberManage';
import PackageMaterialSummaryHistory from 'screens/PackageMaterialSummaryHistory'
import ChannelFulfillment from 'screens/ShippingChannelFulfillment';

let lastPath = '';

const Navigation = (): JSX.Element => {
  const dispatch: Dispatch<any> = useDispatch();
  const { useState } = React;
  const [inited, setInited] = useState(false);
  const [scanToShipVersion, /*setScanToShipVersion*/] = useState(DEFAULT_SCAN_TO_SHIP_VERSION);
  const urlPath: string = useSelector(
    (state: RootState) => state.regular.urlPath,
    shallowEqual
  );
  const [uomEnable, setUomEnable] = useState(false);
  const [lotEnable, setLotEnable] = useState(false);
  const smart = isSmart();

  const ScanToShipComponent = (ps: any) => {
    return (scanToShipVersion === SCAN_TO_SHIP_VERSION2) ? (
      <SalesScanToShip {...ps} />
    ) : (
      <SalesScanVerifyPrint {...ps} />
    );
  };

  const getSetting = useCallback(async () => {
    const res1 = await getProfileSettingValue('EnableLOT', '0');
    const res2 = await getProfileSettingValue('EnableUOM', '0');
    setLotEnable(res1 === '1');
    setUomEnable(res2 === '1');
  }, []);

  useEffect(() => {
    getSetting();
  }, [getSetting]);

  // eslint-disable-next-line
  const handleLocationChange = () => {
    const currentPath = window.location.pathname;

    if (currentPath !== lastPath) {
      const currentIsMobile = currentPath.indexOf(MOBILE_URL_PREFIX) > -1;
      const lastIsMobile = lastPath.indexOf(MOBILE_URL_PREFIX) > -1;

      //console.log('c l', currentIsMobile, lastIsMobile, currentPath, '->', lastPath, '<---', urlPath);
      if (lastIsMobile !== currentIsMobile) {
        //window.location.reload();
      }

      //lastPath = currentPath;
      dispatch(setUrlPath(currentPath));
    }
  };

  useCache();
  React.useEffect(() => {
    if (!inited) {
      //lastPath = window.location.pathname;
      window.addEventListener('popstate', handleLocationChange, false);
      //console.log('----- ----', window.location.pathname);
      dispatch(setUrlPath(window.location.pathname));
      setInited(true);
    }

    lastPath = urlPath;
    //console.log('url', urlPath);
  }, [dispatch, handleLocationChange, inited, urlPath]);

  return (
    <ThemeProvider theme={{ fontSize: DEFAULT_FONT_SIZE }}>
      <Router>
        <Layout style={{ height: '100%' }}>
          {!smart && <SideBar />}
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route path="/products" component={Products} />
            <Route path="/catalog/view" component={CatalogView} />
            <Route path="/catalog/lot-Management" component={LotNumberManage} />
            {/*<Route path="/content/:type" component={ContentWindow} />*/}
            {/* <Route path="/catalog/lots" component={CatalogLots} /> */}
            <Route path="/fulfill" component={Fulfill} />
            <Route path="/inventory/search" component={InventorySearch} />
            <Route path="/inventory/checkin" component={InventoryAdd} />
            <Route path="/inventory/receive" component={InventoryReceive} />
            <Route path="/inventory/checkout" component={InventoryRemove} />
            <Route path="/inventory/audit" component={InventoryAudit} />
            <Route
              path="/inventory/audit-batch"
              component={InventoryAuditBatch}
            />
            <Route path="/inventory/movemerge" component={InventoryMoveMerge} />
            <Route
              path="/inventory/cycle-counting"
              component={InventoryCycleCounting}
            />
            {/* <Route
              path="/inventory/assembleKits"
              component={InventoryAssembleKits}
            /> */}
            <Route
              path="/warehouse-transfer/list"
              component={WarehouseTransfer}
            />
            <Route
              path="/warehouse-transfer/receive"
              component={WarehouseTransferReceive}
            />
            <Route path="/return/list" component={ReturnList} />
            <Route path="/return/receive" component={ReturnReceive} />
            <Route path="/return/restock" component={ReturnStock} />
            <Route path="/return/bulk-return" component={BulkReturn} />
            <Route
              path="/sales/generate-new-pick-list"
              component={SalesGeneratePickList}
            />
            <Route path="/sales/view-orders" component={SalesViewOrders} />
            <Route
              path="/sales/scan-verify-print"
              component={ScanToShipComponent}
            />
            <Route path="/sales/scan-verify-print1" component={SalesScanVerifyPrint} />
            <Route path="/sales/scan-verify-print2" component={SalesScanToShip} />
            <Route path="/administration/accounts" component={AdminAccount} />
            <Route path="/administration/warehouse" component={Warehouse} />
            <Route
              path="/administration/zone-management"
              component={ZoneManagement}
            />
            <Route
              path="/administration/location-management"
              component={LocationManagement}
            />
            <Route
              path="/administration/aspect-management"
              component={AspectManagement}
            />
            <Route path="/administration/zones" component={Zones} />
            <Route
              path="/administration/transationReasons"
              component={TransationReason}
            />
            <Route
              path="/administration/zone-location"
              component={ZoneLocation}
            />
            <Route
              path="/administration/user-profile-settings"
              component={UserProfileSetting}
            />
            <Route
              path="/administration/lot-settings"
              component={LOTSetting}
            />
            <Route
              path="/administration/prepack-settings"
              component={PrepackSetting}
            />
            <Route path="/sales/white-list" component={WhiteList} />
            {/* <Route path="/administration/brands" component={BrandManagement} /> */}
            {/* <Route
              path="/administration/productTag"
              component={ProductTagManagement}
            /> */}
            <Route
              path="/administration/orderTag"
              component={OrderTagManagement}
            />
            <Route
              path="/administration/channel-account"
              component={StoreSetup}
            />
            <Route path="/administration/route-rule" component={RouteRule} />
            <Route
              path="/reports/transaction-log"
              component={TransactionHistory}
            />
            <Route
              path="/reports/special-inventory"
              component={SpecialInventory}
            />
            <Route path="/reports/white-list/history" component={WhiteListHistory} />
            <Route
              path="/administration/address-book"
              component={AddressBook}
            />
            <Route path="/administration/settings" component={Setting} />
            <Route path="/administration/printStation-managment" component={PrinterManage} />
            <Route
              path="/reports/locations-report"
              component={LocationsReport}
            />
            <Route
              path="/reports/employee-shipment"
              component={EmployeeShipment}
            />
            <Route
              path="/reports/shipment-void/history"
              component={ShipmentVoidHistory}
            />
            {/* <Route path="/catalog/create-kit" component={CatalogCreateKit} /> */}
            <Route path="/shipments" component={Shipments} />
            <Route path="/refund" component={Refund} />
            <Route path="/custom-service" component={CustomService} />
            <Route path="/import" component={Import} />
            <Route path="/export" component={Export} />
            <Route
              path="/print/location-labels"
              component={PrintLocationLabel}
            />
            <Route path="/po/list" component={PoList} />
            <Route path="/po/detail" component={PoDetail} />
            {/* <Route path="/po/receive" component={(lotEnable || uomEnable) ? PoReceive2 : PoReceive} /> */}
            <Route path="/po/receive" component={PoReceive2} />
            <Route path="/po/poReceiveBatch" component={ListPoReceive} />
            <Route
              path="/shipment/custom-packages"
              component={ShipmentCustomPackages}
            />
            <Route
              path="/shipment/package-selection"
              component={ShipmentPackageSelection}
            />
            <Route
              path="/shipment/shopping-around"
              component={ShippingAroundScreen}
            />
            <Route
              path="/shipment/shipping-priority"
              component={ShippingPriority}
            />
            <Route path="/shipment/shipping-account" component={Carriers} />
            <Route path="/shipment/shipping-provider-account" component={ProviderAccount} />
            <Route path="/shipment/shipping-channel-fulfillment" component={ChannelFulfillment} />
            <Route path="/sales/shipments" component={ShipmentsList} />
            <Route
              path="/sales/print-generated-picklists"
              component={PrintGeneratedPicklists}
            />
            <Route
              path="/sales/small-packages-to-container"
              component={SmallPackagesToContainer}
            />
            <Route
              path="/reports/receiving-history"
              component={ReceivingHistory}
            />
            <Route
              path="/reports/audit-close/history"
              component={AuditBatchCloseHistory}
            />
            <Route
              path="/reports/po-receive-batch/history"
              component={POBatchReceiveHistory}
            />
            <Route
              path="/reports/reprint/history"
              component={ReprintHistory}
            />
            <Route
              path="/reports/package-material-summary/history"
              component={PackageMaterialSummaryHistory}
            />
            <Route
              path="/pre-pack/pack"
              component={Pack}
            />
            <Route
              path="/pre-pack/unpack"
              component={Unpack}
            />
            <Route
              path="/smart/sku/pick/list"
              component={(lotEnable || uomEnable) ? SmartSKUPickListScreen2 : SmartSKUPickListScreen}
            />
            {/* <Route
              path="/smart/sku/pick/list2"
              component={SmartSKUPickListScreen2}
            /> */}
            <Route
              path="/smart/order/pick/list"
              component={(lotEnable || uomEnable) ? SmartOrderPickListScreen2: SmartOrderPickListScreen}
            />
            {/* <Route
              path="/smart/order/pick/list2"
              component={SmartOrderPickListScreen2}
            /> */}
            <Route path="/smart/pick" component={SmartPickScreen} />
            <Route
              path="/smart/virtualWarehouseToLocation"
              component={SmartVirtualWarehouseToLocation}
            />
            <Route
              path="/smart/returnToLocation"
              component={SmartReturnToLocation}
            />
            <Route
              path="/smart/inspectionToLocation"
              component={SmartInspectionToLocation}
            />
            <Route
              path="/smart/receivingToLocation"
              component={SmartReceivingToLocation}
            />
            <Route path="/smart/audit" component={SmartAudit} />
            <Route path="/smart/checkItem" component={SmartCheckItem} />
            <Route path="/smart/checkLocation" component={SmartCheckLocation} />
            <Route
              path="/smart/resetInventory"
              component={(lotEnable || uomEnable) ? SmartResetInventory2 : SmartResetInventory}
            />
            {/* <Route
              path="/smart/resetInventory2"
              component={SmartResetInventory2}
            /> */}

            <Route path="/smart/movemerge" component={SmartMoveMerge} />
            <Route path="/smart/merge" component={SmartMerge} />
            <Route path="/smart/move" component={(lotEnable || uomEnable) ? SmartMove2 : SmartMove} />
            {/* <Route path="/smart/move2" component={SmartMove2} /> */}
            <Route path="/smart/guided-move" component={(lotEnable || uomEnable) ? SmartGuidedMove2 : SmartGuidedMove} />
            {/* <Route path="/smart/guided-move2" component={SmartGuidedMove2} /> */}
            <Route path="/smart/multiple-move" component={SmartMultipleMove}/>

            <Route exact path="/smart/moveWarehouse" component={SmartMoveWarehouse} />
            <Route exact path="/smart/moveWarehouse/normalToDamage" component={SmartNormalToDamage} />
            <Route exact path="/smart/moveWarehouse/returnDtoDamage" component={SmartReturnDtoDamage} />
            <Route exact path="/smart/moveWarehouse/returnItoReturnD" component={SmartReturnItoReturnD} />
            <Route exact path="/smart/moveWarehouse/returnItoReturnR" component={SmartReturnItoReturnR} />
            <Route exact path="/smart/moveWarehouse/returnRtoReturnD" component={SmartReturnRtoReturnD} />
            <Route exact path="/smart/moveWarehouse/returnRtoDamage" component={SmartReturnRtoDamage} />

            <Route exact path="/smart/moveAllProducts" component={SmartMoveAllProducts} />

            <Route path="/smart/confirm" component={SmartConfirm} />
            <Route path="/smart/confirmByTracking" component={SmartConfirmTracking} />
            <Route path="/smart/prepack" component={SmartPrepack} />
            <Route path="/smart/pickForPack" component={SmartPickForPack} />
            <Route exact path="/smart/unpack" component={SmartUnpack}/>
            <Route exact path="/smart/unpack/item" component={SmartUnpackItem}/>
            <Route exact path="/smart/checkProgress" component={SmartCheckProgress}/>
            <Route path="/smart" component={SmartScreen} />
            <Route path="/administration/CypressDataClear" component={DelTestData} />
          </Switch>
        </Layout>
      </Router>
    </ThemeProvider>
  );
};

export default Navigation;
