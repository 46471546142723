/**
 * The version of the app. format(Date.now(), 'yyyyMM.dd.HHmm');
 */
export const APP_VERSION = '202412.11.0900';

/**
 * Request timeout for axios request.
 */
export const AXIOS_REQUEST_TIMEOUT = 60000;

/**
 * Common date range format.
 */
export const COMMON_DATE_RANGE_FORMAT = 'MM/DD/YYYY HH:mm';

/**
 * Regular dialog height;
 */
export const COMMON_DIALOG_HEIGHT = 600;

/**
 * Common error message display duration.
 * The default duration value. Time(seconds) before auto-dismiss.
 */
export const COMMON_ERR_MSG_DEFAULT_DISPLAY_DURATION = 10;

/**
 * Common form label right padding space.
 */
export const COMMON_FORM_LABEL_RIGHT_PADDING_SPACE = 10;

/**
 * Common message display duration.
 * The default duration value. Time(seconds) before auto-dismiss.
 */
export const COMMON_MSG_DEFAULT_DISPLAY_DURATION = 10;

/**
 * Common padding space.
 */
export const COMMON_PADDING_SPACE = 8;

/**
 * Common CardWrapper marin top.
 */

export const COMMON_CARD_WRAPPER_MARGIN = 20;

/**
 * Content area left padding.
 */
export const CONTENTAREA_LEFT_PADDING = 10;

/**
 * Content area margin space.
 */
export const CONTENTAREA_MARGIN = 10;

/**
 * The margin space for data grid indicator.
 */
export const DATA_GRID_SORT_INDICATOR_MARGIN = 8;

/**
 * Cache key for data grid audit table columns.
 */
export const DATAGRID_CACHE_AUDIT_TABLE_COLS = 'dgc_audit_table_cols';

/**
 * Cache key for data grid inventory search columns.
 */
export const DATAGRID_CACHE_INVENTORY_SEARCH_COLS = 'dgc_inventory_search_cols';

/**
 * Cache key for data grid print picklist columns.
 */
export const DATAGRID_CACHE_PRINT_PICKLIST_COLS = 'dgc_print_picklist_cols';

/**
 * Cache key for data grid shipments columns.
 */
export const DATAGRID_CACHE_SHIPMENTS_COLS = 'dgc_shipment_cols';

/**
 * Cache key for data grid view catalog columns.
 */
export const DATAGRID_CACHE_VIEWCATALOG_COLS = 'dgc_viewcatalog_cols';

/**
 * Cache key for data grid view order columns.
 */
export const DATAGRID_CACHE_VIEWORDER_COLS = 'dgc_vieworder_cols';

/**
 * The icon width of datagrid cell icon.
 */
export const DATAGRID_CELL_ICON_WIDTH1 = 20;

/**
 * Date time format without second.
 */
export const DATE_TIME_NO_SECOND_FORMAT = 'MM/DD/YYYY hh:mm A';

/**
 * Default datagrid row number.
 */
export const DEFAULT_DATAGRID_ROW_NUM = 10;

/**
 * Default date formt, the moment format.
 */
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export const DEFAULT_US_DATE_FORMAT = 'MM/DD/YYYY';

/**
 * Default date time format, the moment format.
 */
// export const DEFAULT_DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm:ss';
export const DEFAULT_DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm:ss A';

/**
 * Default locale.
 */
export const DEFAULT_LOCALE = 'en';

/**
 * Default theme.
 */
export const DEFAULT_THEME = 'old';

/**
 * Default iso time format.
 */
export const DEFAULT_ISO_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

/**
 * Default margin space of the icon.
 */
export const DEFAULT_ICON_MARGIN = 5;


/**
 * Default print mode for print station.
 */
export const DEFAULT_PRINT_MODE = 'Normal';

/**
 * Default value of confirmation for print packing slip.
 */
export const DEFAULT_PRINT_PACKING_SLIP_CONFIRM = 0;

/**
 * Default scan to ship version.
 */
export const DEFAULT_SCAN_TO_SHIP_VERSION = 1;

/**
 * Default width for siderbar.
 */
export const DEFAULT_SIDERBAR_WIDTH = 250;

/**
 * Default warehouse code.
 */
export const DEFAULT_WAREHOUSE_CODE = '';

/**
 * Default warehouse id.
 */
export const DEFAULT_WAREHOUSE_ID = '';

/**
 * Header Title icon size.
 */
export const HEADER_TITLE_ICON_SIZE = 18;

/**
 * The definition for http status code.
 */
export const HTTP_STATUS_OK = 200;

/**
 * The loading icon size.
 */
export const LOADING_ICON_SIZE1 = 48;

/**
 * The loading icon size.
 */
export const LOADING_ICON_SIZE2 = 24;

/**
 * The loading icon size.
 */
export const LOADING_ICON_SIZE3 = 20;

/**
 * The loading icon size.
 */
export const LOADING_ICON_SIZE4 = 16;

/**
 * The loading icon size.
 */
export const LOADING_ICON_SIZE5 = 30;

/**
 * Local storage key for warehouse code.
 */
export const LS_DATAGRID_CACHE_KEY = 'dg.local';

/**
 * Local storage key for warehouse code.
 */
export const LS_DEFAULT_WAREHOUSE_CODE = 'default.warehouse.code';

/**
 * Local storage key for warehouse id.
 */
export const LS_DEFAULT_WAREHOUSE_KEY = 'default.warehouse.id';

/**
 * Local storage key for warehouse id.
 */
export const LS_DEFAULT_WAREHOUSE_WAREHOUSEID = 'default.warehouse.warehouseId';


/**
 * Local storage key for printer config.
 */
export const LS_DEFAULT_PRINTER_CONFIG = 'default.print.config';

/**
 * Local storage key for locale.
 */
export const LS_LOCALE_CODE = 'wmsful.locale';

/**
 * Local storage key for theme.
 */
export const LS_THEME_CODE = 'wmsful.theme';

/**
 * Local storage key for packing printer.
 */
export const LS_PACKING_PRINTER_KEY = 'sale.ss.pprinter';

/**
 * Local storage key for packing printer2.
 */
export const LS_PACKING_PRINTER2_KEY = 'sale.ss.pprinter2';

/**
 * Local storage key for profile fingerprint.
 */
export const LS_PROFILE_FINGERPRINT_KEY = 'profile.fingerprint';

/**
 * Local storage key for scale.
 */
export const LS_SCALE_KEY = 'sale.ss.scale';

/**
 * Local storage key for shipment printer.
 */
export const LS_SHIPMENT_PRINTER_KEY = 'sale.ss.sprinter';

/**
 * Local storage key for shipment container printer.
 */
export const LS_SHIPMENT_CONTAINER_PRINTER_KEY = 'sale.ss.sprinter.shipment_container';

/**
 * Local storage key for shipment printer 4x8.
 */
export const LS_SHIPMENT_PRINTER_4x8_KEY = 'sale.ss.sprinter.4x8';

/**
 * Local storage key for shipment printer coton.
 */
export const LS_SHIPMENT_PRINTER_COTON_KEY = 'sale.ss.sprinter.coton';

/**
 * Local storage key for skip printing packing slip.
 */
export const LS_SKIP_PRINT_PACK_SLIP = 'sale.ss.skip.pack_slip';

/**
 * Local storage key for testing token key.
 */
export const LS_TESTING_PROFILE_KEY = 'testing.profile';

/**
 * Local storage key for testing token key.
 */
export const LS_TESTING_TOKEN_KEY = 'testing.token';

/**
 * The blocking mask time(in second).
 */
export const MASK_BLOCK_INTERVAL = 30;

/**
 * The max length of regular name.
 */
export const MAX_REGULAR_NAME_LENGTH = 64;

/**
 * The top position of the message bar.
 */
export const MESSAGE_BAR_POS_TOP1 = 80;

/**
 * Minimum weight for fedex smart post service.
 */
export const MIN_FEDEX_SMART_POST_WEIGHT = 1;

/**
 * The prefix of the mobile url.
 */
export const MOBILE_URL_PREFIX = '/smart';

/**
 * The prefix of the location for popup window.
 */
export const POPUP_WINDOW_LOCATION_PREFIX = '/popup';

/**
 * Post message to set the profile.
 */
export const POST_MSG_SET_ENV = 'SET_ENV';

/**
 * Post message to set the page title.
 */
export const POST_MSG_SET_TITLE = 'SET_TITLE';

export const POST_MSG_SET_STATE = 'SET_STATE';

/**
 * The precision for the OZ of weight.
 */
export const SCAN_TO_SHIP_OZ_PRECISION = 1;

/**
 * Default version of scan to ship.
 */
export const SCAN_TO_SHIP_VERSION2 = 2;

/**
 * User error message display duration.
 * The default duration value. Time(seconds) before auto-dismiss.
 */
export const USER_ERR_MSG_DEFAULT_DISPLAY_DURATION = 10;

/**
 * User successful message display duration.
 * The default duration value. Time(seconds) before auto-dismiss.
 */
export const USER_SUCCESS_MSG_DEFAULT_DISPLAY_DURATION = 5;

/**
 * Full page mode.
 */
export const PAGE_MODE_FULL_PAGE = 2;

/**
 * Normal page mode.
 */
export const PAGE_MODE_NORMAL = 1;

/**
 * Type definition for category of scan to ship.
 */
export const PRINT_CATEGORY_SCAN_SHIP = 1001;

/**
 * The response timeout for print action.
 */
export const PRINT_MESSAGE_ACTION_TIMEOUT = 2000;

/**
 * The response timeout for print message.
 */
export const PRINT_MESSAGE_RESPONSE_TIMEOUT = 3000;

/**
 * The message timeout for printing.
 */
export const PRINT_MESSAGE_TIMEOUT = 30000;

/**
 * Type definition for print message type.
 */
export const PRINT_MESSAGE_TYPE_ERROR = 2;

/**
 * Print packing slip after confirmation of tracking number.
 */
export const PRINT_PACKING_SLIP_NEED_CONFIRMATION = 1;

/**
 * Type definition for the type of printing label.
 */
export const PRINT_TYPE_LABEL = 1001;

/**
 * Type definition for the type of printing packing slip.
 */
export const PRINT_TYPE_PACK_SLIP = 8;

/**
 * Type definition for the type of reprinting label.
 */
export const PRINT_TYPE_REPRINT_LABEL = 1003;

/**
 * Type definition for the type of printing test label.
 */
export const PRINT_TYPE_TEST_LABEL = 1005;

/**
 * Type definition for the type of printing test label.
 */
export const PRINT_CONTAINER_SHIPPING_LABEL = 1007;

/**
* Type definition for the type of printing test label.
*/
export const REPRINT_CONTAINER_SHIPPING_LABEL = 1009;

/**
 * The length for printed label cache list.
 */
export const PRINTED_LABEL_CACHE_LIST_LENGTH = 500;

/**
 * Regular margin bottom space for header title.
 */
export const REGULAR_HEADER_TITLE_MARGIN_BOTTOM = 12;

/**
 * Regular left space.
 */
export const REGULAR_LEFT_SPACING1 = 10;

/**
 * The icon height for regular select box.
 */
export const REGULAR_SELECTBOX_LOADING_ICON_HEIGHT = 24;

/**
 * Vertical spacing for inner space of sections.
 */
export const REGULAR_SECTION_INNER_VERTICAL_SPACING = 10;

/**
 * Vertical spacing between sections.
 */
export const REGULAR_SECTION_VERTICAL_SPACING = 16;

/**
 * Vertical spacing for common sections.
 */
export const REGULAR_SECTION_ROW_VERTICAL_SPACING = 10;

/**
 * Server error message display duration.
 * The default duration value. Time(seconds) before auto-dismiss.
 */
export const SERVER_ERR_MSG_DEFAULT_DISPLAY_DURATION = 30;

/**
 * Server error message display duration.
 * The development environment duration value. Time(seconds) before auto-dismiss.
 */
export const SERVER_ERR_MSG_DEVELOPMENT_DISPLAY_DURATION = 50;

/**
 * Fedex smart post service number.
 */
export const SERVICE_FEDEX_SMARTPOST_NUM = 44;

/**
 * The shipment item not verified status.
 */
export const SHIPMENT_ITEM_NOT_VERIFY_STATE = 0;

/**
 * The shipment item verified status.
 */
export const SHIPMENT_ITEM_VERIFY_STATE = 1;

/**
 * The shipment label size definition.
 */
export const SHIPMENT_LABEL_SIZE0 = 0;
export const SHIPMENT_LABEL_SIZE1 = 1;

/**
 * The shipment not shipping status;
 */
export const SHIPMENT_NOT_SHIPPING_STATE = 0;

/**
 * The shipment shipped(shipping) status;
 */
export const SHIPMENT_SHIPPING_STATE = 1;

/**
 * The shipment cancelled status;
 */
export const SHIPMENT_CANCELLED_STATE = 16;

/**
 * The shipping label is in waiting for release status.
 */
export const SHIPPING_LABEL_WAIT_RELEASE = 0;

/**
 * The width when the sidebar is collapsed.
 */
export const SIDEBAR_COLLAPSED_WIDTH = 80;

/**
 * The size to get thumbnail information by request.
 */
export const THUMBNAIL_REQUEST_SIZE1 = 50;

/**
 * The size to get thumbnail information by request.
 */
export const THUMBNAIL_REQUEST_SIZE2 = 150;

/**
 * Warehouse location dialog form height.
 */
//export const WAREHOUSE_LOCATION_DIALOG_FORM_HEIGHT = 250;

/**
 * Form row vertical spacing for warehouse creation dialog.
 */
export const WAREHOUSE_CREATION_ROW_VERTICAL_SPACING = 15;

/**
 * Checkbox margin top for warehouse location dialog.
 */
export const WAREHOUSE_LOCATION_CHECKBOX_MARGIN_TOP = 10;

/**
 * The timeout for trigger search.
 */
export const WAREHOUSE_LOCATION_SEARCH_TIMEOUT = 1000;

/**
 * Checkbox vertical spacing for warehouse location dialog.
 */
export const WAREHOUSE_LOCATION_CHECKBOX_VERTICAL_SPACING = 5;
/**
 * The height for image height in grid
 */
export const IMAGE_HEIGHT_IN_GRID = 32;

/**
 * SignalR connection state.
 */
export const SIGNALR_CONNECTION_CONNECTED = 2;

/**
 * SignalR connection state.
 */
export const SIGNALR_CONNECTION_CONNECTING = 1;

/**
 * SignalR connection state.
 */
export const SIGNALR_CONNECTION_DISCONNECTED = 0;
/**
 * The enum definition for product quantity TransactionType.
 */
export enum TransactionType {
  Create = 1,
  Add = 2,
  Remove = 3,
  Pick = 4,
}
/**
 * default font size
 */
export const DEFAULT_FONT_SIZE = 14;
/**
 * middle font size
 */
export const MIDDLE_FONT_SIZE = 20;
/**
 * dimension Unit List
 */
export const DIMENSION_UNITS = ['Inch', 'Feet', 'Centimeter ', 'Meter', 'Millimeter'];

export const DIMENSION_UNITS_ABBR = ['"', 'ft', 'cm ', 'm', 'mm'];
/**
 * weigt unit list
 */
export const WEIGHT_UNITS = ['Pound', 'Ounce', 'Kilogram', 'Gram'];

/**
 * PermissionLevel
 */
export const PERMISSION_LEVEL = {
  'NONE': 0,
  'VIEW': 1,
  'EDIT': 2,
  'ADMIN': 3
};

/**
 * Smart show locations
 */
export const SMART_SHOW_LOCATIONS_RETURN = 'smart_show_locations_return';
export const SMART_SHOW_LOCATIONS_RECEIVING = 'smart_show_locations_receiving';


export const LSK_PROFILE_IS_SELECTED = 'profile_is_selected';
export const LSK_PROFILE_SELECTED_INDEX = 'profile_selected_index';
