import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Row,
  Spin,
  Modal,
  Button,
  Space,
  Select,
  Input,
  Checkbox,
  Card,
} from 'antd';
import { message, FormLabel } from 'components/common';
import { LoadingOutlined, SaveOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { SelectWarehouse } from 'components/common';
import { getShipAccountByWarehouse } from 'services/ship';
import { getCountrys } from 'services/address';
import {
  addChannelAccountShipAccount,
  updateChannelAccountShipAccount,
} from 'services/storeSetup';

interface AddServiceProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  store?: StoreChannelInfo;
  channelShip?: ChannelAccountShipAccountRow;
  carriers: any[];
  // shipList: any[];
}

// eslint-disable-next-line
export default (props: AddServiceProps) => {
  const { visible, onHide, onRefresh, store, channelShip, carriers } = props;
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [shipAccountList, setShipAccountList] = useState<ShipAccountRow[]>([]);
  const [carrierList, setCarrierList] = useState<any[]>([]);
  const [isThirdParty, setIsThirdParty] = useState<boolean>();
  const [isSendShippingFee, setIsSendShippingFee] = useState<boolean>(channelShip ? !!channelShip.sendShippingFee : true);
  const [isDutyThirdParty, setIsDutyThirdParty] = useState<number>();
  // const [selectedAddress, setSelectedAddress] = useState<AddressRow>();
  const [countryList, setCountryList] = useState<CountryRow[]>([]);
  const firstChange = useRef(true);

  const [form] = Form.useForm();

  const handleSubmit = async () => {
    const params = await form.validateFields();
    if (params.errorFields || !store) return;
    const postParams = {
      ChannelAccountShipAccountNum: channelShip
        ? channelShip.channelAccountShipAccountNum
        : 0,
      ChannelAccountNum: store.channelAccountNum,
      ShipAccountNum: params.shippingAccount,
      WarehouseNum: params.warehouseNum.warehouseNum || params.warehouseNum.id,
      BillToType: isThirdParty ? 1 : 0,
      IsDefault: params.isDefault,
      BillToThirdCountryNum: isThirdParty ? params.CountryNum : null,
      BillToThirdPostalCode: isThirdParty ? params.PostalCode : null,
      BillToThirdAccount: isThirdParty ? params.Account : null,
      DutyBillToType: params.DutyBillToType,
      DutyPayorAccount: isDutyThirdParty === 2 ? params.DutyPayorAccount : null,
      DutyPayorCountryNum:
        isDutyThirdParty === 2 ? params.DutyPayorCountryNum : null,
      SendShippingFee: isSendShippingFee ? 1 : 0
    };
    try {
      setSubmitLoading(true);
      let res;
      if (!channelShip) {
        res = await addChannelAccountShipAccount(postParams);
      } else {
        res = await updateChannelAccountShipAccount(postParams);
      }
      setSubmitLoading(false);
      if (res.isSuccess) {
        message.success({
          content: channelShip ? 'Saved successfully' : 'Added successfully',
        });
        onRefresh();
        onHide();
      }
    } catch (error) {
      setSubmitLoading(false);
    }
  };

  const fetchCountryList = async () => {
    try {
      setLoading(true);
      const res = await getCountrys();
      setLoading(false);
      if (res.isSuccess) {
        setCountryList(res.data);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const fetchShipAccountByWarehouse = async (id: number) => {
    try {
      setLoading(true);
      const res = await getShipAccountByWarehouse(id);
      setLoading(false);
      if (res.isSuccess) {
        setShipAccountList(res.data);
        setCarrierList([
          ...new Set(res.data.map((item: ShipAccountRow) => item.carrierNum)),
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        onOk() {
          onHide();
        },
      });
    }
  };
  /* eslint-disable */
  useEffect(() => {
    if (channelShip) {
      fetchShipAccountByWarehouse(channelShip.warehouseNum);
    }
  }, [channelShip]);
  useEffect(() => {
    if (channelShip) {
      setIsThirdParty(!!channelShip.billToType);
      setIsDutyThirdParty(channelShip.dutyBillToType);
    }
  }, []);
  useEffect(() => {
    fetchCountryList();
  }, []);
  /* eslint-enable */

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  };
  return (
    <Modal
      title={`${channelShip ? 'Edit' : 'Add'} Shipping Account for ${store?.channelAccountName
        }`}
      centered
      visible={visible}
      maskClosable={false}
      width={800}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={submitLoading}
              icon={channelShip ? <SaveOutlined /> : <PlusOutlined />}
            >
              {submitLoading
                ? `${channelShip ? 'Saving' : 'Adding'}`
                : `${channelShip ? 'Save' : 'Add'}`}
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>Cancel</Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={submitLoading || loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form} style={{ marginTop: 20 }}>
          <Form.Item
            name="warehouseNum"
            initialValue={
              channelShip ? { id: channelShip.warehouseNum } : undefined
            }
            label={<FormLabel>Warehouse</FormLabel>}
            rules={[{ required: true, message: 'please select warehouse' }]}
          >
            <SelectWarehouse
              onChange={(warehouse) => {
                if (firstChange.current && channelShip) {
                  firstChange.current = false
                } else {
                  form.setFieldsValue({
                    shippingAccount: undefined,
                  });
                }
                fetchShipAccountByWarehouse(warehouse.id);
              }}
              placeholder="please select warehouse"
            />
          </Form.Item>

          <Form.Item
            name="shippingAccount"
            initialValue={channelShip ? channelShip.shipAccountNum : undefined} // not working! unknow reason.
            label={<FormLabel>Shipping Account</FormLabel>}
            rules={[
              { required: true, message: 'please select a shipping account' },
            ]}
          >
            <Select
              disabled={!form.getFieldValue('warehouseNum')}
              showSearch
              defaultValue={channelShip ? channelShip.shipAccountNum : undefined}
              optionFilterProp="label"
            >
              {(carrierList || []).map((item) => (
                <Select.OptGroup
                  label={(carriers || []).filter((i) => i?.carrierNum === item)[0]?.name}
                  key={item}
                >
                  {(shipAccountList || [])
                    .filter((i) => i?.carrierNum === item)
                    .map((child) => (
                      <Select.Option
                        key={child?.shipAccountNum}
                        value={child?.shipAccountNum}
                        label={child?.name}
                      >
                        {child?.name}
                      </Select.Option>
                    ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="isDefault"
            initialValue={channelShip?.isDefault}
            label={<FormLabel>Is Default</FormLabel>}
          >
            <Select>
              <Select.Option value={0}>Non-default</Select.Option>
              <Select.Option value={1}>Default</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="DutyBillToType"
            initialValue={channelShip?.dutyBillToType}
            label={<FormLabel>Duty payor</FormLabel>}
          >
            <Select onChange={(value) => setIsDutyThirdParty(value)}>
              <Select.Option value={0}>Sender</Select.Option>
              <Select.Option value={1}>Receiver</Select.Option>
              <Select.Option value={2}>Third party</Select.Option>
            </Select>
          </Form.Item>

          {isDutyThirdParty === 2 && (
            <>
              <Form.Item
                name="DutyPayorAccount"
                initialValue={channelShip?.dutyPayorAccount}
                label={<FormLabel>Third Party Account #</FormLabel>}
                rules={[
                  {
                    required: true,
                    message: 'please input Third Party Account',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="DutyPayorCountryNum"
                initialValue={
                  channelShip?.dutyPayorCountryNum
                    ? channelShip?.dutyPayorCountryNum
                    : undefined
                }
                label={<FormLabel>Country</FormLabel>}
                rules={[{ required: true, message: 'please select Country' }]}
              >
                <Select showSearch optionFilterProp="label">
                  {countryList.map((item) => (
                    <Select.Option
                      key={item.countryNum}
                      value={item.countryNum}
                      label={item.name}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </>
          )}

          <Form.Item
            label={
              <Checkbox
                checked={isThirdParty}
                onChange={(e) => setIsThirdParty(e.target.checked)}
              >
                Bill To Third Party
              </Checkbox>
            }
          >
            <Checkbox
              checked={isSendShippingFee}
              onChange={(e) => setIsSendShippingFee(e.target.checked)}
            >
              Send Shipping Fee to ERP
            </Checkbox>
          </Form.Item>

          {isThirdParty && (
            <Card>
              <Form.Item
                name="Account"
                initialValue={channelShip?.billToThirdAccount}
                label={<FormLabel>Third Party Account #</FormLabel>}
                rules={[
                  {
                    required: true,
                    message: 'please input Third Party Account',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="PostalCode"
                initialValue={channelShip?.billToThirdPostalCode}
                label={<FormLabel>Zip Code</FormLabel>}
                rules={[{ required: true, message: 'please input Zip Code' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="CountryNum"
                initialValue={
                  channelShip?.billToThirdCountryNum
                    ? channelShip?.billToThirdCountryNum
                    : undefined
                }
                label={<FormLabel>Country</FormLabel>}
                rules={[{ required: true, message: 'please select Country' }]}
              >
                <Select showSearch optionFilterProp="label">
                  {countryList.map((item) => (
                    <Select.Option
                      key={item.countryNum}
                      value={item.countryNum}
                      label={item.name}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Card>
          )}

        </Form>
      </Spin>
    </Modal>
  );
};
