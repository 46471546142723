import React, { useCallback, useEffect, useState } from 'react';
import {
  Row,
  Button,
  Space,
  Form,
  Card,
  Input,
  Typography,
  DatePicker,
  Select,
  // Image,
  InputNumber,
  Modal,
  Col,
} from 'antd';
import {
  CheckCircleOutlined,
  CloseOutlined,
  DiffOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  UnorderedListOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
// import SelectProduct from 'components/common/SelectProduct';
// import CreatePoDialog from './CreatePoDialog';
import moment from 'moment';
import {
  // SelectLocationPlus as SelectLocation,
  DataGridPlus,
  ButtonIcon,
  message,
  HeaderTitle,
  EditText,
  // SelectWarehouse,
  FormLabel,
  CopySpan,
  SetDefaultWarehouseDialog,
  TablesFilter,
} from 'components/common';
import SelectLocationDialog from 'components/common/SelectLocationPlus/SelectLocationDialog';
import { GridIconBox } from 'components/common/styles';
import {
  fetchPoList,
  fetchPoLineList,
  patchPo,
  getPoNumberByKeyword,
} from 'services/purchaseOrder';
import { fetchWarehouseLocationList } from 'services/warehouse';
import { getProductLotList } from 'services/product';
// import fallbackImage from 'assets/images/logo192.png';
import ReceiveDialog from './ReceiveDialog';
import ScanToReceiveDialog from './ScanToReceive2';
import SelectPoDialog from './SelectPoDialog';
import ReceiveAllDialog from './ReceiveAllDialog';
import { LS_DEFAULT_WAREHOUSE_KEY } from 'constants/config';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import { poControlStatus } from 'constants/enums';
import theme from 'assets/styles/theme';
import {
  getProfileSettingValue,
  getWarehouseCodeFromCache,
  onSelectionChange,
} from 'utils';
import GenerateLotDialog from './GenerateLotDialog';
import { fetchLOTSettings } from 'services/lotSetting';
import SelectUOM from './SelectUomDialog';
import CreatePoLine from './AddLineDialog';
import CopyFromExcelDialog from './CopyFromExcel';
import SelectLotDialog from 'components/Catalog/LotNumberView/SelectLotNumberDialog'

const { Text } = Typography;
const { Search } = Input;

type ColumnRenderType = {
  data: PoLineRow;
  rowIndex: number;
};
// eslint-disable-next-line
export default () => {
  const hash = useLocation();
  const searchParams = new URLSearchParams(hash.search);
  const defaultSearch = searchParams.get('PoNumber');
  const warehouses = useSelector(
    (state: RootState) => state.admin.warehouses,
    shallowEqual
  );
  const userProfileSettings = useSelector(
    (state: RootState) => state.admin.userProfileSettings,
    shallowEqual
  );
  const [selectPoDialogVisible, setSelectPoDialogVisible] = useState(false);
  const [receiveDialog, setReceiveDialog] = useState(false);
  const [scanToReceiveDialogVisible, setScanToReceiveDialogVisible] =
    useState(false);

  const [poList, setPoList] = useState<PoRow[]>([]);
  const [selectedPo, setSelectedPo] = useState<PoRow | undefined>();
  const [lines, setLines] = useState<PoLineRow[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<
    WarehouseRow | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [receiptDate, setReceiptDate] = useState<moment.Moment>(moment());
  const [selectSearchType, setSelectSearchType] = useState<string>('poNumber');
  const [current, setCurrent] = useState<PoLineRow>();
  const [selectLocationDialogVisible, setSelectLocationDialogVisible] =
    useState(false);
  const [locations, setLocations] = useState<any[]>([]);
  const [receiveAllDialogVisible, setReceiveAllDialogVisible] =
    useState<boolean>(false);
  const [crossWarehouseReceive, setCrossWarehouseReceive] =
    useState<string>('');
  const [whsList, setWhsList] = useState<any[]>([]);
  const [currentWarehouse, setCurrentWarehouse] = useState<any>();
  const [showSelectWarehouseDialog, setShowSelectWarehouseDialog] =
    useState(false);
  const [defaultWarehouse, setDefaultWarehouse] = useState<
    WarehouseRow | undefined
  >();
  const [dialogClosable, setDialogClosable] = useState(true);
  const [defaultWarehouseVisible, setDefaultWarehouseVisible] = useState(false);
  const [selected, setSelected] = useState<any>({});
  const [selectedRow, setSelectedRow] = React.useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);
  const [isAll, setIsAll] = useState<boolean>(false);
  const [enablePOOverReceive, setEnablePOOverReceive] = useState<any>('0');
  const [lotNumberGenerationType, setLotNumberGenerationType] = useState<
    0 | 1 | 2
  >(0); // 0： all, 1: sys only, 2: user only
  const [lotEnable, setLotEnable] = useState(true);
  const [noUniqueControl, setNoUniqueControl] = useState(true);
  const [generateLotDialogVisible, setGenerateLotDialogVisible] =
    useState(false);
  const [generateRows, setGenerateRows] = useState<PoLineRow[]>([]);
  const [uomEnable, setUomEnable] = useState(true);
  const [inited, setInited] = useState(false);
  const [isGenerate, setIsGenerate] = useState(false);
  const [timeZone, setTimeZone] = useState<string>('');
  const [selectUomDialogVisible, setSelectUomDialogVisible] = useState(false);
  const [checking, setChecking] = useState(false);
  const [addLineDialogVisible, setAddLineDialogVisible] = useState(false);
  const [enableExtra, setEnableExtra] = useState(false);
  const [cfeVisible, setCfeVisible] = useState(false);
  const [selectLotDialogVisible, setSelectLotDialogVisible] = useState(false);


  React.useEffect(() => {
    if (userProfileSettings) {
      const temp = userProfileSettings.filter(
        (e) => e.settingCode === 'CrossWarehouseReceive'
      );
      if (temp.length > 0) {
        setCrossWarehouseReceive(temp[0].settingValue);
      }
      const temp1 = userProfileSettings.filter(
        (e) => e.settingCode === 'EnablePOReceiveExtra'
      );
      if (temp1.length > 0) {
        setCrossWarehouseReceive(temp[0].settingValue);
      }

      
    }
  }, [userProfileSettings]);

  const getLOTSettings = useCallback(async () => {
    try {
      const res = await fetchLOTSettings();
      if (res.isSuccess) {
        setLotNumberGenerationType(res.data.generation);
        setNoUniqueControl(!!res.data.unique);
      }
    } catch (error) {}
  }, []);

  const getEnablePOOverReceive = useCallback(async () => {
    const res = await getProfileSettingValue('EnablePOOverReceive', '0');
    const res1 = await getProfileSettingValue('EnableLOT', '0');
    const res2 = await getProfileSettingValue('EnableUOM', '0');
    const res3 = await getProfileSettingValue('TimeZone');
    const res4 = await getProfileSettingValue('EnablePOReceiveExtra');
    setEnablePOOverReceive(res);
    setLotEnable(res1 === '1');
    setUomEnable(res2 === '1');
    setEnableExtra(res4 === '1');
    setTimeZone(res3);
    if (res1 === '1') {
      getLOTSettings();
    }
    setInited(true);
  }, [getLOTSettings]);

  useEffect(() => {
    getEnablePOOverReceive();
  }, [getEnablePOOverReceive]);

  const checkWarehouse = useCallback(
    (po: PoRow | undefined) => {
      if (
        crossWarehouseReceive &&
        crossWarehouseReceive === '0' &&
        selectedWarehouse &&
        po &&
        po.warehouseCode !== selectedWarehouse.code &&
        po.warehouseCode !== selectedWarehouse.correspondingCode
      ) {
        Modal.info({
          title: 'Not allow cross warehouse receive.',
          content: (
            <div>
              <p>
                If you wish to allow this, please go to the Profile Setting page
                to modify the settings.
              </p>
            </div>
          ),
          onOk() {},
        });
      } else {
        setSelectedPo(po);
      }
    },
    [crossWarehouseReceive, selectedWarehouse]
  );

  const checkLotNumber = useCallback(
    async (data: PoLineRow) => {
      if (checking) return;
      if (data.lotNumber && data.lotNumber.trim()) {
        try {
          setChecking(true);
          const res = await getProductLotList(data.productId);
          setChecking(false);
          if (res && res.data) {
            const temp = res.data.filter(
              (i: any) => i.lotNumber === data.lotNumber
            );
            if (temp.length === 0) {
              Modal.confirm({
                title: `LotNumber[${data.lotNumber}] cannot be found for SKU[${data.sku}].`,
                content: '',
                okText: 'OK',
                okType: 'primary',
                cancelText: 'Create This LotNumber',
                autoFocusButton: 'cancel',
                onCancel() {
                  setIsGenerate(false);
                  setGenerateRows([{ ...data }]);
                  setGenerateLotDialogVisible(true);
                  setLines((prev) => {
                    const temp = prev;
                    const index = prev.findIndex(
                      (item) => item.poLineNum === data.poLineNum
                    );
                    if (index > -1) {
                      temp[index] = {
                        ...temp[index],
                        lotNumber: undefined,
                      };
                      return [...temp];
                    } else {
                      return prev;
                    }
                  });
                },
                onOk() {
                  setLines((prev) => {
                    const temp = prev;
                    const index = prev.findIndex(
                      (item) => item.poLineNum === data.poLineNum
                    );
                    if (index > -1) {
                      temp[index] = {
                        ...temp[index],
                        lotNumber: undefined,
                      };
                      return [...temp];
                    } else {
                      return prev;
                    }
                  });
                },
              });
            }
          }
        } catch (error) {
          setChecking(false);
        }
      }
    },
    [checking]
  );

  const getColumns = useCallback(() => {
    const columns = [
      // {
      //   name: 'picture',
      //   defaultFlex: 1,
      //   resizable: false,
      //   sortable: false,
      //   maxWidth: 100,
      //   minWidth: 100,
      //   textAlign: 'center',
      //   textVerticalAlign: 'middle',
      //   renderHeader: () => '',
      //   render: (value: ColumnRenderType) => {
      //     const { data } = value;
      //     return (
      //       <Image
      //         width={32}
      //         height={32}
      //         preview={false}
      //         src={data.thumbnailUrl || 'error'}
      //         fallback={fallbackImage}
      //         onClick={() => {
      //           console.log('click picture', data);
      //         }}
      //         style={{ cursor: 'pointer' }}
      //       />
      //     );
      //   },
      // },
      {
        name: 'sku',
        header: 'SKU',
        minWidth: 220,
        userSelect: true,
        showColumnMenuTool: true,
        defaultFlex: 1,
      },
      {
        name: 'title',
        header: 'Product Name',
        userSelect: true,
        showColumnMenuTool: true,
        defaultFlex: 1,
      },

      {
        name: 'quantity',
        header: 'PO Qty',
        userSelect: true,
        showColumnMenuTool: true,
        defaultFlex: 1,
      },
      {
        name: 'orderUom',
        header: 'PO UOM',
        userSelect: true,
        showColumnMenuTool: true,
        defaultFlex: 1,
      },
      {
        name: 'orderUomRate',
        header: 'PO Rate',
        userSelect: true,
        showColumnMenuTool: true,
        defaultFlex: 1,
      },
      {
        name: 'orderBaseQty',
        header: 'PO Base Qty',
        userSelect: true,
        showColumnMenuTool: true,
        defaultFlex: 1,
      },
      {
        name: 'receivingQty',
        header: 'Receiving Qty',
        sortable: false,
        showColumnMenuTool: true,
        defaultFlex: 1,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          const {
            humanReceiveQty = 0,
            closingQty = 0,
            waitCloseQty = 0,
            receivingQty = 0,
            quantity = 0,
          } = data;
          const sum =
            humanReceiveQty + closingQty + waitCloseQty + receivingQty;
          return (
            <Space>
              <InputNumber
                prefix={
                  <span
                    style={{ fontSize: 14, color: '#ff4d4f', lineHeight: 1 }}
                  >
                    *
                  </span>
                }
                value={data.receivingQty}
                min={0}
                onChange={(qty) =>
                  setLines((prev) => {
                    const temp = prev;
                    const index = prev.findIndex(
                      (item) => item.poLineNum === data.poLineNum
                    );
                    if (index > -1) {
                      temp[index] = {
                        ...temp[index],
                        receivingQty: qty,
                      };
                      return [...temp];
                    } else {
                      return prev;
                    }
                  })
                }
              />
              {quantity === sum ? (
                <CheckCircleOutlined />
              ) : quantity < sum ? (
                <InfoCircleOutlined />
              ) : null}
            </Space>
          );
        },
      },
      {
        name: 'lotNumber',
        header: 'LOT#',
        minWidth: 200,
        sortable: false,
        showColumnMenuTool: true,
        defaultFlex: 1,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return (
            <Space>
              <Input
                value={data.lotNumber}
                disabled={lotNumberGenerationType === 1}
                prefix={
                  data.lotControl === 1 ? (
                    <span
                      style={{ fontSize: 14, color: '#ff4d4f', lineHeight: 1 }}
                    >
                      *
                    </span>
                  ) : undefined
                }
                onChange={(e) => {
                  setLines((prev) => {
                    const temp = prev;
                    const index = prev.findIndex(
                      (item) => item.poLineNum === data.poLineNum
                    );
                    if (index > -1) {
                      temp[index] = {
                        ...temp[index],
                        lotNumber: e.target.value,
                      };
                      return [...temp];
                    } else {
                      return prev;
                    }
                  });
                }}
                onBlur={() => {
                  checkLotNumber(data);
                }}
                onPressEnter={() => checkLotNumber(data)}
                // onKeyDown={(e) => e.keyCode === 9 && checkLotNumber(data)}

                // onClick={() => {
                //   setIsGenerate(false)
                //   setGenerateRows([{ ...data }]);
                //   setGenerateLotDialogVisible(true);
                // }}
              />
              <Button icon={<SearchOutlined />} onClick={()=>{
                setCurrent(data)
                setSelectLotDialogVisible(true)}}></Button>
              {lotNumberGenerationType !== 2 && (
                <Button
                  onClick={() => {
                    setIsGenerate(true);
                    setGenerateRows([{ ...data }]);
                    setGenerateLotDialogVisible(true);
                  }}
                  icon={<PlusOutlined />}
                />
              )}
            </Space>
          );
        },
      },
      {
        name: 'uom',
        header: 'Receiving UOM',
        minWidth: 150,
        sortable: false,
        showColumnMenuTool: true,
        defaultFlex: 1,
        isUom: true,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return (
            <>
              <span style={{ fontSize: 14, color: '#ff4d4f', lineHeight: 1 }}>
                *
              </span>
              {data.uom || ''}
              <GridIconBox>
                <EditOutlined
                  onClick={() => {
                    setCurrent(data);
                    setSelectUomDialogVisible(true);
                  }}
                />
              </GridIconBox>
            </>
          );
        },
      },
      {
        name: 'uomRate',
        header: 'Receiving Rate',
        minWidth: 150,
        sortable: false,
        showColumnMenuTool: true,
        defaultFlex: 1,
        isUom: true,
      },
      {
        name: 'baseQty',
        header: 'Receiving Base Qty',
        minWidth: 150,
        sortable: false,
        showColumnMenuTool: true,
        defaultFlex: 1,
        isUom: true,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return data.receivingQty
            ? data.receivingQty * (data.uomRate || 1)
            : 0;
        },
      },
      {
        name: 'humanReceiveQty',
        header: 'Total Received Base Qty',
        userSelect: true,
        showColumnMenuTool: true,
        sortable: false,
        defaultFlex: 1,
      },
      {
        name: 'receivingWarehouseCode',
        header: 'Receipt Warehouse',
        userSelect: true,
        sortable: false,
        defaultFlex: 1,
      },
      {
        name: 'receivingLocationCode',
        header: 'Receipt Location',
        showColumnMenuTool: true,
        defaultFlex: 1,
        maxWidth: 200,
        minWidth: 200,
        render: (value: ColumnRenderType) => {
          const { data } = value;
          return (
            <>
              <span style={{ fontSize: 14, color: '#ff4d4f', lineHeight: 1 }}>
                *
              </span>
              {data.receivingLocationCode || ''}
              <GridIconBox>
                <EditOutlined
                  onClick={() => {
                    setCurrent(data);
                    setSelectLocationDialogVisible(true);
                  }}
                />
              </GridIconBox>
            </>
          );
        },
      },
      {
        name: 'waitCloseQty',
        header: 'Waiting for Closing',
        userSelect: true,
        showColumnMenuTool: true,
        defaultFlex: 1,
      },
      {
        name: 'closingQty',
        header: 'Closing',
        userSelect: true,
        showColumnMenuTool: true,
        defaultFlex: 1,
      },
      {
        name: 'batchNumber',
        header: 'Received Batch',
        showColumnMenuTool: true,
        userSelect: true,
        defaultFlex: 1,
      },
      {
        name: 'publicNote',
        header: 'Note',
        userSelect: true,
        showColumnMenuTool: true,
        defaultFlex: 1,
      },
    ];
    let temp = [...columns];
    temp = lotEnable ? temp : temp.filter((i) => i.name !== 'lotNumber');
    temp = uomEnable ? temp : temp.filter((i) => !i.isUom);
    return temp;
  }, [lotEnable, lotNumberGenerationType, uomEnable, checkLotNumber]);

  const rowStyle = (rowData: any) => {
    const { data = {} } = rowData;
    const {
      humanReceiveQty = 0,
      closingQty = 0,
      waitCloseQty = 0,
      receivingQty = 0,
      quantity = 0,
    } = data;
    const sum = humanReceiveQty + closingQty + waitCloseQty + receivingQty;
    return {
      backgroundColor:
        quantity === sum
          ? theme['@success-color']
          : quantity < sum
          ? theme['@danger-color']
          : '#fff',
    };
  };

  const getLocations = async (id: string) => {
    const list = await fetchWarehouseLocationList(id, false);
    setLocations(
      list.map((row: any) => {
        return {
          id: row.locationNum,
          code: row.locationCode,
          parentCode: row.parentCode,
          parent: row.parentNum,
          creator: row.createBy,
          reserved: row.reserved,
          backstock: row.backstock,
          warehouseId: row.warehouseId,
          ...row,
        };
      })
    );
  };

  const getPoList = useCallback(
    async (poNumberSearchValue, typeName = 'poNumber') => {
      try {
        const polist = await fetchPoList({
          // warehouseCode: tempWarehouseCode,
          filterFieldName: typeName,
          filterKey: poNumberSearchValue || '',
          poStatusNum: -1,
          sortBy: 'fulfillmentPoNum desc',
          skip: 0,
          limit: 100,
        });
        if (polist.data.length === 0) {
          return message.warning({ content: 'no PO matched' });
        }
        setPoList(
          polist.data.map((item) => {
            return { ...item, receivingQty: 0 };
          })
        );
        if (polist.data.length > 1) {
          setSelectPoDialogVisible(true);
        } else {
          checkWarehouse(polist.data[0]);
          // setSelectedPo(polist.data[0]);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [checkWarehouse]
  );

  const getPoLines = useCallback(
    async (id: string, warehouseCode: string, update?: boolean) => {
      try {
        setLoading(true);
        const data = await fetchPoLineList(
          id,
          crossWarehouseReceive === '0' ? warehouseCode : undefined
        );
        setLoading(false);
        if(update){

          setLines(prev=>{
            const tempIds = prev.map((i:any)=>i.fulfillmentPoLineNum)
            const newLine = data.filter((i:any)=> tempIds.indexOf(i.fulfillmentPoLineNum) < 0)
            return [
              ...prev,
              ...newLine.map((item:any) =>{
                return {
                  ...item,
                  receivingLocationCode: undefined,
                  receivingLocationNum: undefined,
                  receivingWarehouseCode: selectedWarehouse?.warehouseCode,
                  uom: item.uom || 'EA',
                  uomRate: item.uomRate || 1,
                  baseQty: 0,
                  orderUom: item.uom,
                  orderUomRate: item.uomRate,
                  orderBaseQty: item.uomBaseQty,
                }
              })
            ]
          })
        } else {
          setLines(
            data.map((item) => {
              return {
                ...item,
                receivingLocationCode: undefined,
                receivingLocationNum: undefined,
                receivingWarehouseCode: selectedWarehouse?.warehouseCode,
                uom: item.uom || 'EA',
                uomRate: item.uomRate || 1,
                baseQty: 0,
                orderUom: item.uom,
                orderUomRate: item.uomRate,
                orderBaseQty: item.uomBaseQty,
              };
            })
          );
        }

      } catch (err) {
        setLoading(false);
      }
    },
    [crossWarehouseReceive, selectedWarehouse]
  );

  const getPoListBySku = useCallback(
    async (sku) => {
      try {
        // const sku = form.getFieldValue('SearchSku');
        const polist = await getPoNumberByKeyword(sku);
        if (polist.length === 0) {
          message.warning({ content: 'no PO matched' });
          return;
        }
        setPoList(
          polist.map((item: any) => {
            return { ...item, receivingQty: 0 };
          })
        );
        if (polist.length > 1) {
          setSelectPoDialogVisible(true);
        } else {
          checkWarehouse({
            ...polist[0],
            poId: polist[0].fulfillmentPoId,
            poNum: polist[0].fulfillmentPoNum,
          });
          // setSelectedPo({
          //   ...polist[0],
          //   poId: polist[0].fulfillmentPoId,
          //   poNum: polist[0].fulfillmentPoNum,
          // });
          // getPoLines(polist[0].fulfillmentPoId);
        }
      } catch (error) {}
    },
    [checkWarehouse]
  );

  const EditPublicNote = useCallback(
    async (note: string) => {
      if (!selectedPo) {
        message.warning({ content: 'First selected a PO' });
        return;
      }
      try {
        const res = await patchPo(
          selectedPo.poId,
          note,
          selectedPo.privateNote
        );
        if (res) {
          setSelectedPo((prev) => {
            if (prev) {
              return {
                ...prev,
                publicNote: note,
              };
            }
          });
          message.success({ content: 'save Po publicNote successful!' });
        } else {
          message.error({ content: 'save Po publicNote failed!' });
        }
      } catch (err) {
        console.log(err);
      }
    },
    [selectedPo]
  );

  const checkAllLotNumber = async (ls: PoLineRow[]) =>{
    ls.map(async (item)=>{
      if(item.receivingLocationNum&& item.receivingQty && item.lotNumber && item.lotNumber.trim()){
        try {
          setChecking(true);
          const res = await getProductLotList(item.productId);
          setChecking(false);
          if (res && res.data) {
            const temp = res.data.filter(
              (i: any) => i.lotNumber === item.lotNumber
            );
            if (temp.length === 0) {
              Modal.confirm({
                title: `LotNumber[${item.lotNumber}] cannot be found for SKU[${item.sku}].`,
                content: '',
                okText: 'OK',
                okType: 'primary',
                cancelText: 'Create This LotNumber',
                autoFocusButton: 'cancel',
                onCancel() {
                  setIsGenerate(false);
                  setGenerateRows([{ ...item }]);
                  setGenerateLotDialogVisible(true);
                  setLines(prev=>{
                    const temp = [...prev]
                    const index = temp.findIndex(
                      (it) => it.poLineNum === item.poLineNum
                    );
                    if(index > -1){
                      temp[index] = {
                        ...temp[index],
                        lotNumber: item.lotNumber
                      }
                    }
                    return [...temp]
                  })

                },
                onOk() {
                  setLines(prev=>{
                    const temp = [...prev]
                    const index = temp.findIndex(
                      (it) => it.poLineNum === item.poLineNum
                    );
                    if(index > -1){
                      temp[index] = {
                        ...temp[index],
                        lotNumber: undefined
                      }
                    }
                    return [...temp]
                  })
                },
              });
            }
          }
        } catch (error) {
          setChecking(false);
        }
      }
    })
  }

  const handelReceive = () => {
    let flag = true;
    lines.forEach((line) => {
      if (
        line.receivingQty &&
        line.receivingQty !== null &&
        !line.receivingLocationCode
      ) {
        flag = false;
      }
      if (
        line.receivingQty &&
        line.receivingQty !== null &&
        lotEnable &&
        line.lotControl === 1 &&
        !line.lotNumber
      ) {
        flag = false;
      }
    });
    if (!flag) {
      const confirmModal = Modal.confirm({
        title: (
          <Row justify="space-between">
            {/* <Col span={22}>{`You haven't selected a receipt location for some item(s) yet. Do you want to continue receiving the other goods?`}</Col> */}
            <Col
              span={22}
            >{`You haven't selected a receipt location or set a LOT# for some item(s) yet.`}</Col>
            <Col span={2}>
              <CloseOutlined onClick={() => confirmModal.destroy()} />
            </Col>
          </Row>
        ),
        okText: 'Cancel',
        // cancelText: 'Continue',
        cancelButtonProps: {
          style: { display: 'none' },
        },
        okType: 'default',
        // onCancel() {
        //   setReceiveDialog(true);
        // }
      });
    }
    setReceiveDialog(flag);
  };

  /**
   * receive all event
   */
  const handelReceiveAll = useCallback((location: WarehouseLocationRow) => {
    setLines((prev: PoLineRow[]) => {
      const temp = prev.map((item) => {
        return {
          ...item,
          receivingLocationCode: location.code,
          receivingLocationNum: location.id,
          receivingQty: item.quantity,
        };
      });
      return [...temp];
    });
  }, []);

  const onSelectLocation = (value: any, applyToAll?: boolean) => {
    if (applyToAll) {
      setLines((prev) => {
        return prev.map((item) => {
          return {
            ...item,
            receivingLocationCode: value.code,
            receivingLocationNum: value.id,
          };
        });
      });
      return;
    }
    setLines((prev) => {
      const temp = prev;
      const index = prev.findIndex(
        (item) => item.poLineNum === current?.poLineNum
      );
      if (index > -1) {
        temp[index] = {
          ...temp[index],
          receivingLocationCode: value.code,
          receivingLocationNum: value.id,
        };
        return [...temp];
      } else {
        return temp;
      }
    });
  };

  const onSelectUom = (value: any) => {
    setLines((prev) => {
      const temp = prev;
      const index = prev.findIndex(
        (item) => item.poLineNum === current?.poLineNum
      );
      if (index > -1) {
        temp[index] = {
          ...temp[index],
          uom: value.uom,
          uomRate: value.uomRate,
        };
        return [...temp];
      } else {
        return temp;
      }
    });
  };

  const openSetDefaultWarehouseDialog = (config: StringKAnyVPair) => {
    setDialogClosable(
      typeof config.closable === 'boolean' ? config.closable : true
    );
    setDefaultWarehouseVisible(true);
  };

  /**
   * handel warehouse change event
   */
  // const handleWarehouseChange = useCallback((warehouse: WarehouseRow) => {
  //   setSelectedWarehouse(warehouse);
  //   getLocations(warehouse.warehouseId);
  //   //clear setted location
  //   setLines((prev) => {
  //     const temp = prev.map((item) => {
  //       return {
  //         ...item,
  //         receivingLocationCode: undefined,
  //         receivingLocationNum: undefined,
  //       };
  //     });
  //     return [...temp];
  //   });
  // }, []);

  useEffect(() => {
    const temp = warehouses.filter(
      (i: any) =>
        i.id === Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
    );
    if (temp.length > 0) {
      // setSelectedWarehouse(temp[0]);
      setDefaultWarehouse(temp[0]);
      const temp2 = warehouses.filter(
        (i: WarehouseRow) =>
          i.warehouseCode === temp[0].warehouseCode ||
          (i.correspondingCode === temp[0].warehouseCode &&
            i.warehouseType === 6)
      );
      setWhsList(temp2);
      if (temp2.length > 1) {
        setCurrentWarehouse(temp[0].warehouseNum);
        setShowSelectWarehouseDialog(true);
      } else {
        setSelectedWarehouse(temp[0]);
      }
    }
  }, [warehouses]);

  useEffect(() => {
    if (selectedWarehouse) {
      getLocations(selectedWarehouse.warehouseId);
      setLines((prev) => {
        const t = [...prev];
        const temp = t.map((i) => {
          return {
            ...i,
            receivingLocationCode: undefined,
            receivingLocationNum: undefined,
            receivingWarehouseCode: selectedWarehouse.warehouseCode,
          };
        });
        return temp;
      });
    }
  }, [selectedWarehouse]);

  useEffect(() => {
    if (selectedPo && selectedPo.poNum) {
      getPoLines(selectedPo.poId, selectedPo.warehouseCode);
    }
  }, [selectedPo, getPoLines]);

  useEffect(() => {
    if (defaultSearch) {
      getPoList(defaultSearch);
    }
  }, [defaultSearch, getPoList]);

  React.useEffect(() => {
    if (
      !localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) ||
      !getWarehouseCodeFromCache(
        Number(localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY))
      )
    ) {
      openSetDefaultWarehouseDialog({ closable: false });
    }
  }, []);

  return (
    <>
      <HeaderTitle
        breadcrumb={[
          'PO',
          `PO Receive (Warehouse : ${defaultWarehouse?.code})`,
        ]}
      />
      <Card style={{ justifyContent: 'center' }}>
        <Row justify="center">
          <Input.Group compact style={{ width: 580 }}>
            <Select
              defaultValue="poNumber"
              onChange={(value) => setSelectSearchType(value)}
              style={{ width: '20%' }}
            >
              <Select.Option value="poNumber">PO#</Select.Option>
              <Select.Option value="SKU">SKU</Select.Option>
              <Select.Option value="Container #">Container#</Select.Option>
            </Select>
            <Search
              style={{ width: '80%' }}
              defaultValue={defaultSearch ? defaultSearch : undefined}
              loading={loading}
              placeholder={
                selectSearchType === 'poNumber'
                  ? 'Please input PO number.'
                  : selectSearchType === 'SKU'
                  ? 'Please input SKU'
                  : 'Please input container'
              }
              enterButton={
                <>
                  <UnorderedListOutlined />
                  Show POs
                </>
              }
              onSearch={(value) => {
                if (selectSearchType === 'poNumber') {
                  getPoList(value);
                } else if (selectSearchType === 'SKU') {
                  getPoListBySku(value);
                } else {
                  getPoList(value, 'Container #');
                }
              }}
            />
          </Input.Group>
        </Row>
      </Card>
      <Card style={{ marginTop: 20 }}>
        <Card>
          <Row justify="center" align="middle">
            <Space size={50}>
              <Form.Item label={<FormLabel>Current PO#</FormLabel>}>
                {/* <Text>{selectedPo?.poNumber}</Text> */}
                <CopySpan value={selectedPo?.poNumber || ''} />
              </Form.Item>
              <Form.Item label={<FormLabel>PO Warehouse</FormLabel>}>
                {/* <Text>{selectedPo?.warehouseCode}</Text> */}
                <CopySpan value={selectedPo?.warehouseCode || ''} />
              </Form.Item>
              <Form.Item label={<FormLabel>Control Status</FormLabel>}>
                {/* <Text>{selectedPo?.warehouseCode}</Text> */}
                <CopySpan
                  value={poControlStatus[`${selectedPo?.controlStatus}`] || ''}
                />
              </Form.Item>
            </Space>
          </Row>
          <Row justify="center" align="middle">
            <Space size={50}>
              <Form.Item label={<FormLabel>Container #</FormLabel>}>
                {/* <Text>{selectedPo?.container}</Text> */}
                <CopySpan value={selectedPo?.container || ''} />
              </Form.Item>
              <Form.Item label={<FormLabel>Supplier</FormLabel>}>
                {/* <Text>{selectedPo?.vendorName}</Text> */}
                <CopySpan value={selectedPo?.vendorName || ''} />
              </Form.Item>
              <Form.Item label={<FormLabel>Public Notes</FormLabel>}>
                <EditText
                  value={selectedPo?.publicNote || ''}
                  showIcon={selectedPo ? true : false}
                  onSubmit={(value) => EditPublicNote(value)}
                />
              </Form.Item>
            </Space>
          </Row>

          <Row justify="center" align="middle">
            <Space>
              <FormLabel>Receipt Warehouse</FormLabel>
              <Text>{selectedWarehouse?.code}</Text>

              <FormLabel>Receipt Date</FormLabel>
              <DatePicker
                allowClear={false}
                style={{ marginLeft: 10 }}
                defaultValue={moment()}
                format="MM/DD/YYYY"
                onChange={(date) => setReceiptDate(date || moment())}
              />
              <Button
                type="primary"
                disabled={
                  !selectedWarehouse ||
                  !selectedPo ||
                  selectedPo.controlStatus === 2
                }
                onClick={async() => {
                  handelReceive()
                }}
                icon={<DiffOutlined />}
              >
                Confirm Receipt
              </Button>
              {/* <Button
            disabled={!selectedPo}
            onClick={() => setReceiveToLotDialogVisible(true)}
          >
            Scan to Lot
          </Button> */}
            </Space>
          </Row>
        </Card>
        <Card
          title={
            <Row align="middle">
              <Space>
                <Text>PO lines</Text>
              {enableExtra && <Button
                disabled={!selectedPo}
                onClick={() => setAddLineDialogVisible(true)}
              >
                Add Line
              </Button>}
                <TablesFilter
                  columns={getColumns()}
                  dataSource={lines}
                  setFilteredData={setFilteredData}
                />
              </Space>
            </Row>
          }
          extra={
            <Space>
              {lotEnable && (
                <Button
                  disabled={selectedRow.length === 0}
                  onClick={() => {
                    setIsGenerate(true);
                    setGenerateRows([...selectedRow]);
                    setGenerateLotDialogVisible(true);
                  }}
                >
                  Generate LOT#
                </Button>
              )}
              <Button
                disabled={
                  !selectedWarehouse ||
                  lines.length === 0 ||
                  selectedPo?.controlStatus === 2 ||
                  selectedRow.length === 0
                }
                onClick={() => {
                  setIsAll(false);
                  setReceiveAllDialogVisible(true);
                }}
                icon={<DiffOutlined />}
              >
                Choose Location
              </Button>
              <Button
                disabled={
                  !selectedWarehouse ||
                  lines.length === 0 ||
                  selectedPo?.controlStatus === 2
                }
                onClick={() => {
                  setCfeVisible(true)
                }}
              >
                Copy From Excel
              </Button>
              <Button
                disabled={
                  !selectedWarehouse ||
                  lines.length === 0 ||
                  selectedPo?.controlStatus === 2
                }
                onClick={() => {
                  setIsAll(true);
                  setReceiveAllDialogVisible(true);
                }}
                icon={<DiffOutlined />}
              >
                Receive All
              </Button>
              <Button
                disabled={!selectedPo}
                onClick={() => setScanToReceiveDialogVisible(true)}
              >
                <ButtonIcon className="bi-upc-scan" />
                Scan to Receive
              </Button>
            </Space>
          }
          bodyStyle={{ padding: 12 }}
        >
          {selectedWarehouse && inited && (
            <DataGridPlus
              autoWith={false}
              showScrollButton
              rowStyle={rowStyle}
              showHoverRows={false}
              columns={getColumns()}
              loading={loading}
              dataSource={filteredData}
              idProperty="poLineNum"
              showColumnMenuTool={false}
              selected={selected}
              checkboxColumn
              onSelectionChange={(props) =>
                onSelectionChange(
                  props,
                  lines,
                  setSelected,
                  setSelectedRow,
                  'poLineNum'
                )
              }
              checkboxOnlyRowSelect={true}
              {...({} as any)}
            />
          )}
        </Card>
      </Card>
      {selectPoDialogVisible && (
        <SelectPoDialog
          onHide={() => {
            setSelectPoDialogVisible(false);
          }}
          onOk={(po) => {
            setSelectPoDialogVisible(false);
            if (po.poNum) {
              checkWarehouse(po);
              // setSelectedPo(po);
            } else {
              getPoList(po.poNumber);
            }
          }}
          poList={poList}
        />
      )}

      {receiveDialog && selectedPo && selectedWarehouse && (
        <ReceiveDialog
          enablePOOverReceive={enablePOOverReceive}
          warehouse={selectedWarehouse}
          PoId={selectedPo?.poId}
          poWarehouseCode={selectedPo?.warehouseCode}
          lines={lines
            .filter(
              (item) =>
                item.receivingLocationCode &&
                item.receivingQty &&
                item.receivingLocationNum &&
                item.poLineNum
            )
            .filter((i) => {
              if (!i.lotNumber) {
                if (i.lotControl === 1 && lotEnable) {
                  return false;
                }
              }
              return true;
            })}
          dateString={moment(receiptDate).format('YYYY-MM-DD')}
          onRefresh={() => {
            getPoLines(selectedPo.poId, selectedPo.warehouseCode);
          }}
          onHide={() => setReceiveDialog(false)}
          lotEnable={lotEnable}
          uomEnable={uomEnable}
        />
      )}
      {/* {addItemDialogVisible && selectedPo && (
        <AddItemDialog
          PoId={selectedPo?.poNum}
          onShowCreateProduct={() => setCreateDialogVisible(true)}
          onRefresh={(tempLines) => {
            const skus = lines.map((item) => item.sku);
            setLines((prev) => {
              const temp = [...prev];
              tempLines.map((item) => {
                if (skus.indexOf(item.sku) < 0) {
                  temp.push(item);
                }
                return true;
              });
              return [...temp];
            });
            setAddItemDialogVisible(false);
          }}
          onHide={() => setAddItemDialogVisible(false)}
        />
      )} */}
      {selectedPo && scanToReceiveDialogVisible && (
        <ScanToReceiveDialog
          targetStrs={lines.map((i) => {
            return {
              sku: i.sku,
              upc: i.upc || ''
            }
          })}
          onHide={() => setScanToReceiveDialogVisible(false)}
          onSuccess={(st) => {
            setLines((prev) => {
              const tmp = prev;
              const skus = prev.map((item) => item.sku);
              (st || []).map((item, index) => {
                const i = skus.indexOf(item.sku);
                if (i > -1) {
                  // tmp.push({
                  //   // ...item.product,
                  //   ...item.product,
                  //   poNum: selectedPo.poNum,
                  //   receivingQty: item.quantity,
                  //   sku: item.sku,
                  //   productNum: item.product.id,
                  //   publicNode: selectedPo.publicNote,
                  //   privateNote: selectedPo.privateNote,
                  //   title: item.product.productTitle,
                  //   quantity: item.product.quantity ? item.product.quantity : 0,
                  // });
                  tmp[i].receivingQty = tmp[i].receivingQty
                    ? item.quantity + (tmp[i].receivingQty || 0)
                    : item.quantity;
                }
                return true;
              });
              return [...tmp];
            });
            setScanToReceiveDialogVisible(false);
          }}
        />
      )}
      {/* {receiveToLotDialogVisible && (
        <ReceiveToLotDialog
          onHide={() => setReceiveToLotDialogVisible(false)}
          skulist={lines.map((item) => item.sku)}
          onSubmit={(lot) => {
            setSelectedLot(lot);
            setReceiveToLotDialogVisible(false);
            setScanToLotVisible(true);
          }}
          onCreateLot={() => {
            setReceiveToLotDialogVisible(false);
            setCreateLotDialogVisible(true);
          }}
        />
      )} */}
      {/* {scanToLotVisible && (
        <ScantoLotDialog
          onHide={() => setScanToLotVisible(false)}
          lot={selectedLot}
          onSubmit={(st) => {
            setLines((prev) => {
              const tmp = prev;
              prev.map((item, index) => {
                if (st[item.sku]) {
                  tmp[index].receivingQty = st[item.sku];
                }
                return true;
              });
              return [...tmp];
            });
            setScanToLotVisible(false);
          }}
        />
      )}
      {createDialogVisible && (
        <CreateProductDialog onHide={() => setCreateDialogVisible(false)} />
      )} */}
      {selectLocationDialogVisible && current && (
        <SelectLocationDialog
          mode="poReceive"
          visible={true}
          dataSource={locations}
          onSelect={(value, applyToAll?: boolean) => {
            onSelectLocation(value, applyToAll);
            setSelectLocationDialogVisible(false);
            setCurrent(undefined);
          }}
          onHide={() => {
            setSelectLocationDialogVisible(false);
            setCurrent(undefined);
          }}
        />
      )}
      {receiveAllDialogVisible && selectedWarehouse && (
        <ReceiveAllDialog
          isAll={isAll}
          onHide={() => setReceiveAllDialogVisible(false)}
          onSuccess={(location) => {
            if (isAll) {
              handelReceiveAll(location);
            } else {
              setLines(
                lines.map((line) => {
                  if (
                    selectedRow.findIndex(
                      (row) => row.poLineNum === line.poLineNum
                    ) > -1
                  ) {
                    return {
                      ...line,
                      receivingLocationCode: location.code,
                      receivingLocationNum: location.id,
                    };
                  } else {
                    return line;
                  }
                })
              );
            }
            setReceiveAllDialogVisible(false);
          }}
          warehouseNum={selectedWarehouse.id}
        />
      )}
      {generateLotDialogVisible && (
        <GenerateLotDialog
          onClose={() => {
            setGenerateLotDialogVisible(false);
          }}
          onSave={(data: { [key: string]: any }) => {
            setGenerateLotDialogVisible(false);
            setLines(
              lines.map((line) => {
                if (line.poLineNum && data[line.poLineNum]) {
                  return {
                    ...line,
                    lotNumber: data[line.poLineNum],
                  };
                } else {
                  return line;
                }
              })
            );
          }}
          noUniqueControl={noUniqueControl}
          rows={generateRows}
          generate={isGenerate}
          timeZone={timeZone}
          lotNumberGenerationType={lotNumberGenerationType}
          visible
        />
      )}
      {selectUomDialogVisible && current && (
        <SelectUOM
          data={current}
          onHide={() => setSelectUomDialogVisible(false)}
          onOk={(row) => {
            onSelectUom(row);
            setReceiveAllDialogVisible(false);
            setCurrent(undefined);
          }}
        />
      )}
      {showSelectWarehouseDialog && (
        <Modal
          visible
          title="Select Receipt Warehouse"
          centered
          cancelButtonProps={{ style: { display: 'none' } }}
          okButtonProps={{ disabled: !currentWarehouse }}
          keyboard={false}
          destroyOnClose
          maskClosable={false}
          closable={false}
          onOk={() => {
            const tmp = whsList.filter(
              (i: WarehouseRow) => i.warehouseNum === currentWarehouse
            );
            if (tmp.length > 0) {
              setSelectedWarehouse(tmp[0]);
              setShowSelectWarehouseDialog(false);
            }
          }}
          onCancel={() => setShowSelectWarehouseDialog(false)}
        >
          <Row justify="center" align="middle">
            <Space>
              <FormLabel>Receipt Warehouse</FormLabel>
              {/* <Text>{selectedWarehouse?.code}</Text> */}
              <Select
                style={{ minWidth: 300 }}
                onChange={setCurrentWarehouse}
                value={currentWarehouse}
              >
                {whsList.map((i: WarehouseRow) => (
                  <Select.Option
                    value={i.warehouseNum}
                    key={i.warehouseNum}
                  >{`${i.warehouseCode} (${
                    i.warehouseType === 6 ? 'Receiving' : 'Normal'
                  })`}</Select.Option>
                ))}
              </Select>
            </Space>
          </Row>
        </Modal>
      )}
      {
        addLineDialogVisible && selectedPo &&
        <CreatePoLine 
          PoId={selectedPo.poId} 
          onHide={()=>{setAddLineDialogVisible(false)}} 
          onRefresh={()=>{
            setAddLineDialogVisible(false)
            getPoLines(selectedPo.poId, selectedPo.warehouseCode, true);
          }}/>
      }
      {
        cfeVisible && selectedPo &&
        <CopyFromExcelDialog
          warehouseCode={selectedWarehouse?.warehouseCode || ''}
          PoId={selectedPo?.poId}
          lines={lines}
          locations={locations}
          lotEnable={lotEnable}
          uomEnable={uomEnable}
          onHide={()=>{
            setCfeVisible(false)
          }}
          onSuccess={(ls)=>{
            setCfeVisible(false)
            setLines([...ls])
            checkAllLotNumber(ls)
          }}
        />
      }
      {selectLotDialogVisible && current && (
        <SelectLotDialog 
          sku={current.sku}
          onClose={()=>{setSelectLotDialogVisible(false)}}
          onSelect={(lotNumber)=> {
            setLines((prev) => {
              const temp = prev;
              const index = prev.findIndex(
                (item) => item.poLineNum === current.poLineNum
              );
              if (index > -1) {
                temp[index] = {
                  ...temp[index],
                  lotNumber: lotNumber,
                };
                return [...temp];
              } else {
                return prev;
              }
            });
            setSelectLotDialogVisible(false)
          }}
          visible
        />
      )}
      <SetDefaultWarehouseDialog
        closable={dialogClosable}
        close={() => {
          setDefaultWarehouseVisible(false);
        }}
        visible={defaultWarehouseVisible}
      />
    </>
  );
};
