import React, { useEffect, useState, useCallback } from 'react';
import { Form, Row, Spin, Modal, Button, Space, Table, Tooltip } from 'antd';
import { leaveEditForm } from 'utils';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { createPoLine } from 'services/purchaseOrder';

type DataItem = {
  id: string;
  sku: string;
  title?: string;
  receivingQty: number;
  receivingLocationCode: string;
  receivingLocationNum: number;
  validsku: boolean;
  validLocation: boolean;
  validQty: boolean;
  lotNumber?: string;
  uom?: string;
  uomRate?: number;
  validUom?: boolean;
};

type Props = {
  onHide: (data?: any) => void;
  reback: () => void;
  items: DataItem[];
  lines: any[];
  PoId: string;
  warehouseCode: string;
  uomEnable: boolean;
  lotEnable: boolean;
};

// eslint-disable-next-line
export default (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const { onHide, items, lines, PoId, warehouseCode, uomEnable, lotEnable } =
    props;
  const [tempLines, setTempLines] = useState<any[]>([]);
  const [allChecked, setAllChecked] = useState(false);

  const [form] = Form.useForm();

  const addLine = useCallback(
    async (item: any, idx: number) => {
      try {
        setCreateLoading(true);
        const res: any = await createPoLine(PoId, {
          SKU: item.sku,
          PoQty: item.receivingQty,
          Title: item.title,
        });
        setCreateLoading(false);
        if (res) {
          const tmp = [...tempLines];
          tmp[idx] = {
            ...res,
            productId: item.productId,
            receivingQty: item.receivingQty,
            receivingLocationCode: item.receivingLocationCode,
            receivingLocationNum: item.receivingLocationNum,
            receivingWarehouseCode: warehouseCode,
            lotNumber: item.lotNumber,
            uom: item.uom || res.uom || 'EA',
            uomRate: item.uomRate || res.uomRate || 1,
            baseQty: 0,
            poLineNum: res.fulfillmentPoLineNum,
            quantity: res.poQty,
            orderUom: res.uom,
            orderUomRate: res.uomRate,
            orderBaseQty: res.uomBaseQty,
          };
          setTempLines([...tmp]);
        }
      } catch (error) {
        setCreateLoading(false);
      }
    },
    [PoId, tempLines, warehouseCode]
  );

  const delLine = useCallback(
    (idx: number) => {
      const tmp = [...tempLines];
      tmp.splice(idx, 1);
      setTempLines([...tmp]);
    },
    [tempLines]
  );

  const checkLines = useCallback((lines: any) => {
    let bl: boolean = true;
    if (lines.length === 0) {
      bl = false;
    }
    lines.map((i: any) => {
      if (!i.fulfillmentPoLineNum) {
        bl = false;
      }
      return true;
    });
    setAllChecked(bl);
  }, []);

  const initLines2 = useCallback(() => {
    try {
      setLoading(true);
      const temp: any[] = [];
      const poLineNums: number[] = [];

      items.map((i) => {
        const index = lines.findIndex(
          (k) => k.sku === i.sku && !poLineNums.includes(k.fulfillmentPoLineNum)
        );
        // console.log('index',index)
        if (index > -1) {
          poLineNums.push(lines[index].fulfillmentPoLineNum);
          temp.push({
            ...lines[index],
            receivingQty: i.receivingQty,
            receivingLocationCode: i.receivingLocationCode,
            receivingLocationNum: i.receivingLocationNum,
            lotNumber: i.lotNumber,
            uom: i.uom,
            uomRate: i.uomRate,
          });
        } else {
          temp.push({ ...i });
        }
        return true;
      });
      const otherArr = lines.filter(
        (i) => !poLineNums.includes(i.fulfillmentPoLineNum)
      );
      setTempLines([...otherArr, ...temp]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [items, lines]);

  // const initLines = useCallback(() => {
  //   try {
  //     setLoading(true);
  //     const temp: any[] = [];
  //     const ids: string[] = [];
  //     lines.map((i) => {
  //       const index = items.findIndex(
  //         (k) => k.sku === i.sku && !ids.includes(k.id)
  //       );
  //       if (index > -1) {
  //         ids.push(items[index].id);
  //         temp.push({
  //           ...i,
  //           receivingQty: items[index].receivingQty,
  //           receivingLocationCode: items[index].receivingLocationCode,
  //           receivingLocationNum: items[index].receivingLocationNum,
  //         });
  //       } else {
  //         temp.push({ ...i });
  //       }
  //       return true;
  //     });
  //     setTempLines(temp);
  //     setLoading(false);
  //   } catch (error) {
  //     setLoading(false);
  //   }
  // }, [items, lines]);

  useEffect(() => {
    initLines2();
  }, [initLines2]);

  useEffect(() => {
    checkLines(tempLines);
  }, [checkLines, tempLines]);

  /**
   * handle submit event
   */
  const handleSubmit = useCallback(async () => {
    onHide(tempLines);
  }, [onHide, tempLines]);
  /**
   * handle cancel event
   */
  const handleCancel = useCallback(async () => {
    const cancel = await leaveEditForm({}, form);
    if (cancel) {
      onHide();
    }
  }, [onHide, form]);

  const getColumns = useCallback(() => {
    const columns = [
      {
        title: 'SKU',
        dataIndex: 'sku',
        key: 'sku',
      },
      {
        dataIndex: 'quantity',
        title: 'PO Qty',
        key: 'quantity',
      },
      {
        dataIndex: 'receivingQty',
        title: 'Receiving Qty',
        key: 'receivingQty',
      },
      {
        dataIndex: 'receivingLocationCode',
        title: 'Receipt Location',
        key: 'receivingLocationCode',
      },
      {
        dataIndex: 'lotNumber',
        title: 'LOT#',
        key: 'lotNumber',
      },
      {
        dataIndex: 'uom',
        title: 'Receiving UOM',
        key: 'uom',
      },
      {
        title: 'Receiving Rate',
        dataIndex: 'uomRate',
        key: 'uomRate',
      },
      {
        dataIndex: 'fulfillmentPoLineNum',
        title: 'Action',
        key: 'fulfillmentPoLineNum',
        render: (value: any, record: any, index: number) => {
          return value ? null : (
            <Space>
              <Tooltip title="Add to Line">
                <Button
                  type="text"
                  onClick={() => {
                    addLine(record, index);
                  }}
                  icon={<PlusOutlined />}
                />
              </Tooltip>
              <Tooltip title="Remove">
                <Button
                  type="text"
                  placeholder="Remove"
                  onClick={() => {
                    delLine(index);
                  }}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Space>
          );
        },
      },
    ];
    let temp = [...columns];
    temp = lotEnable ? temp : temp.filter((i) => i.key !== 'lotNumber');
    temp = uomEnable
      ? temp
      : temp.filter((i) => i.key !== 'uom' && i.key !== 'uomRate');
    return temp;
  }, [lotEnable, uomEnable, addLine, delLine]);

  return (
    <Modal
      title={`Copy From Excel - Preview`}
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleCancel}
      footer={
        <Row justify="space-between">
          <Space>
            <Button onClick={() => props.reback()}>Back</Button>
          </Space>
          <Space>
            <Button
              disabled={loading || !allChecked}
              key="submit"
              type="primary"
              onClick={handleSubmit}
              icon={<CheckOutlined />}
            >
              Apply
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin spinning={loading || createLoading}>
        <Table
          style={{ width: '100%' }}
          rowKey="poLineNum"
          dataSource={tempLines}
          columns={getColumns()}
          pagination={false}
          size="small"
        />
      </Spin>
    </Modal>
  );
};
