import React from 'react';
import { Alert, Button, Row, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';

type Props = {
  // eslint-disable-next-line
  onClick?: (evt: any) => void;
};

const MessageRow = styled(Row)`
  & .ant-alert {
    font-size: 16px;
    width: 100%;
  }
`;

const NewTabWarning = (props: Props) => {
  const newTabMessage = () => {
    return (<>
      <Typography.Text>Please open new tab to use.</Typography.Text>
      <Button
        href={window.location.origin}
        onClick={props.onClick}
        target="_blank"
        type="link"
      >
        <PlusOutlined />
        New Tab
      </Button>
    </>);
  };

  return (
    <MessageRow>
      <Alert message={newTabMessage()} type="error" />
    </MessageRow>
  );
};

export default NewTabWarning;
