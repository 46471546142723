import React from 'react';
import { SiteContent } from 'components/common/styles';
import ContentLayout from 'components/ContentLayout';
import ChannelFulfillment from 'components/ShipmentSettings/ChannelFulfillment';
// eslint-disable-next-line
export default () => {
  return (
    <ContentLayout>
      <SiteContent>
        <ChannelFulfillment />
      </SiteContent>
    </ContentLayout>
  );
};
