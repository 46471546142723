import * as actionTypes from '../constants/profiles';

const initialState: ProfilesState = {
  profiles: [
    {
      Email: '',
      ProfileNum: 0,
      MasterAccountNum: 0,
      DisplayName: '',
    },
  ],
  selectedIndex: 0,
  permissions: new Map(),
  isProfileSelected: false,
};

const profiles = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_PROFILES:
      return { ...state, profiles: action.profiles };
    case actionTypes.SET_PERMISSIONS:
      return { ...state, permissions: action.permissions };
    case actionTypes.SET_SELECTED_INDEX:
      return { ...state, selectedIndex: action.selectedIndex };
    case actionTypes.SET_IS_PROFILE_SELECTED:
      return { ...state, isProfileSelected: action.isProfileSelected };
    default:
      return state;
  }
};

export default profiles;
