import React, { useState } from 'react';
import {
  Form,
  Row,
  Spin,
  Modal,
  Button,
  Space,
  Select,
  Input,
  Checkbox,
} from 'antd';
// import { message } from 'components/common';
import { FormLabel } from 'components/common';
// import { leaveEditForm } from 'utils';
import {
  LoadingOutlined,
  SaveOutlined,
  PlusOutlined,
  CloseOutlined,
} from '@ant-design/icons';
// import { SelectWarehouse } from 'components/common';
import {
  addChannelfulfillmentAccount,
  editChannelfulfillmentAccount,
} from 'services/shippingProviderAccount';
// import { getCarrierServices } from 'services/shipment';

interface AddServiceProps {
  visible: boolean;
  onHide: () => void;
  onRefresh: () => void;
  account: any;
  configInfo: any;
}

// eslint-disable-next-line
export default (props: AddServiceProps) => {
  const { visible, onHide, onRefresh, account, configInfo } = props;
  const [loading, setLoading] = useState(false);
  // const [shipProviderType, setShipProviderType] = useState<number>()

  const [form] = Form.useForm();
  document.body.style.overflowY = 'hidden';

  const handleSubmit = async () => {
    const params = await form.validateFields();
    if (params.errorFields) return;
    const postParams = {
      ChannelFulfillmentAccountNum: account
        ? account.channelFulfillmentAccountNum
        : 0,
      ...params,
      SandBox: params.SandBox ? 1 : 0,

    };
    try {
      setLoading(true);
      let res;
      if (!account) {
        res = await addChannelfulfillmentAccount(postParams);
      } else {
        res = await editChannelfulfillmentAccount(postParams);
      }
      setLoading(false);
      if (res.isSuccess) {
        document.body.style.overflowY = 'unset';
        onHide();
        onRefresh();
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    const cancel = form.isFieldsTouched();
    if (!cancel) {
      document.body.style.overflowY = 'unset';
      onHide();
    } else {
      Modal.confirm({
        title: 'Leave / Refresh Page?',
        okText: 'Leave',
        cancelText: 'Cancel',
        content: `Changes you made may not be saved.`,
        afterClose: () => {
          //fuck antd bug!
          setTimeout(() => {
            document.body.style.overflowY = 'unset';
          }, 0);
        },
        onOk() {
          onHide();
        },
      });
    }
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  return (
    <Modal
      title={`${account ? 'Edit' : 'Add'} Channel Fulfillment`}
      centered
      visible={visible}
      maskClosable={false}
      width={800}
      destroyOnClose={true}
      onCancel={handleCancel}
      getContainer={false}
      footer={
        <Row justify="end">
          <Space>
            <Button
              id="save_or_add_button"
              key="submit"
              type="primary"
              onClick={handleSubmit}
              loading={loading}
            >
              {account ? <SaveOutlined /> : <PlusOutlined />}
              {loading
                ? `${account ? 'Saving' : 'Adding'}`
                : `${account ? 'Save' : 'Add'}`}
            </Button>
            <Button
              id="cancel_button"
              onClick={handleCancel}
              icon={<CloseOutlined />}
            >
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Form {...layout} form={form}>
          <Form.Item
            name="ChannelNum"
            initialValue={account ? account.channelNum : undefined}
            label={<FormLabel>Channel</FormLabel>}
            rules={[{ required: true, message: 'please select channel' }]}
          >
            <Select id="ShipProviderType">
              {configInfo.map((i: any) => (
                <Select.Option
                  key={i.channelNum}
                  value={i.channelNum}
                >
                  {i.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="Name"
            initialValue={account ? account.name : undefined}
            label={<FormLabel>Name</FormLabel>}
            rules={[
              {
                required: true,
                message: 'please input name',
              },
            ]}
          >
            <Input disabled={!!account} id="name" />
          </Form.Item>

          <Form.Item
            name="SandBox"
            valuePropName="checked"
            label={null}
            {...tailFormItemLayout}
            initialValue={
              account ? (account.sandbox ? true : false) : undefined
            }
          >
            <Checkbox> Sandbox</Checkbox>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};
