import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { Alert, Button, Col, Space, Row } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'assets/styles/app.less';
import { setAuthenticationResult } from 'actions/regularActions';
import CypressDoor from 'components/CypressDoor';
import ProfileSelector from 'components/Booter/ProfileSelector';
import { Loading, ScreenMask, message } from 'components/common';
import { InfoButton } from 'components/common/styles';
import Navigation from 'components/Navigation';
import {
  APP_VERSION,
  LOADING_ICON_SIZE1,
  LS_DEFAULT_WAREHOUSE_CODE,
  LS_DEFAULT_WAREHOUSE_KEY,
  LS_DEFAULT_WAREHOUSE_WAREHOUSEID,
  LS_TESTING_PROFILE_KEY,
  LS_TESTING_TOKEN_KEY,
  LS_THEME_CODE,
} from 'constants/config';
import 'i18n/config';
import { loadPrinterList } from 'middlewares/lazyCacheMiddleware';
import { RootState } from 'reducers';
import { convertTimeByUtc, convertToUtcTime, customConsole, setPageTitle } from 'utils';
//import { testBusinessDay } from 'utils/BusinessRange';
import { getUserProfile, logout, userProfiles, getUserProfileSettings } from 'utils/auth';
import { cacheWarehouseList } from 'utils/cache';
import { tokenKeeper } from 'services/http';
import locale from 'antd/es/date-picker/locale/en_US';
// import _profileActions from './actions/profilesAction';
import {
  ENTRANCE_PORTAL,
  getEntrance,
  // getProfileList,
  getSelectedProfileIndex,
  //setSelectedProfileIndex,
} from './utils/authenticate';

locale.lang.ok = 'OK';

declare global {
  var __convertToUtcTime__: Function;
  var __convertTimeByUtc__: Function;
  var __FULFILLMENT_VERSION__: String;
}

const theme = localStorage.getItem(LS_THEME_CODE) || '';
if (theme === 'new') require('./assets/styles/newTheme.less');

customConsole();

function App() {
  const dispatch: Dispatch<any> = useDispatch();
  // const profilesState = useSelector((state: any) => state.profiles);

  // const actions = bindActionCreators(_profileActions, dispatch);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [cacheLoaded, setCacheLoaded] = React.useState(false);
  const [inited, setInited] = React.useState(false);
  const [maskBgColor, setMaskBgColor] = React.useState('#EEE');
  const [profileLoaded, setProfileLoaded] = React.useState(false);
  const [profileSelected, setProfileSelected] = React.useState(false);
  const [profiles, setProfiles] = React.useState<StringKAnyVPair[]>([]);
  const [profileSettings, setProfileSettings] = React.useState<StringKAnyVPair[]>([]);
  const [readyData, setReadyData] = React.useState(false);
  const [cacheError, setCacheError] = React.useState(false);
  const [userReady, setUserReady] = React.useState(false);
  const pageIsHeld: boolean = useSelector(
    (state: RootState) => state.regular.pageIsHeld
  );

  const isTestEntry =
    process.env.NODE_ENV === 'staging' && window.location.pathname === '/test';

  // eslint-disable-next-line
  const enterApp = (index: number) => {
    //userProfiles.profileIndex = profileIndex;
    userProfiles.profileIndex = index;
    //console.log('profile', userProfiles.getProfile(profileIndex));
    setUserReady(true);
    dispatch(
      setAuthenticationResult({
        authed: true,
        userIsExpired: false,
        userIsValid: true,
      })
    );
    setProfileSelected(true);
    //console.log(`fv ${APP_VERSION}`);
  };

  // eslint-disable-next-line
  const fetchUserProfile = async () => {
    const entrance = getEntrance();
    const token = localStorage.getItem('Authorization');

    setPageTitle();

    if (token && 'string' === typeof token) {
      const profile = await getUserProfile(token);
      // console.log(profile)

      //console.log('profile', profile);
      if (profile) {
        tokenKeeper.apiToken = token;
        // console.log('t', tokenKeeper.apiToken);

        if (profile.length === 1) {
          const profileIndex = parseInt(
            localStorage.getItem('some.profile.key') || '0'
          );

          enterApp(profileIndex);
        } else if (profile.length > 1) {
          if( entrance === ENTRANCE_PORTAL){
            const idx = getSelectedProfileIndex();
            if(idx >= 0){
              enterApp(idx);
            }
          } else {
            const fingerprint = userProfiles.getProfileFingerprint();

            setProfiles(profile);
  
            if (fingerprint && typeof fingerprint === 'object') {
              for (let i = 0; i < profile.length; i++) {
                if (
                  profile[i].masterAccountNum === fingerprint.masterAccountNum &&
                  profile[i].profileNum === fingerprint.profileNum
                ) {
                  //userProfiles.removeProfileFingerprint();
                  enterApp(i);
                  break;
                }
              }
            }
          }
          

        } else {
          setAlertMessage(
            'Your account is not activated, please contact your administrator.'
          );
        }
      } else {
        setMaskBgColor('#FFF');
        setProfileSelected(true);
        setAlertMessage(`Can not identify the user's identity`);
        /*
        setUserReady(true); // comment this line in the future
        dispatch(setAuthenticationResult({
          authed: true,
          userIsExpired: false,
          userIsValid: true,
        }));
        */
      }
    } else {
      // temp code, comment this line in the future
      //setUserReady(true); // comment this line in the future
    }
  };
  // eslint-disable-next-line
  const loadCache = async () => {
    try {
      await loadWarehouseListCache();
      await loadPrinterList(dispatch);
      setReadyData(true);
    } catch (e) {
      console.log('err', e);
      setCacheError(true);
    }
  };

  const loadWarehouseListCache = async () => {
    const data = await cacheWarehouseList();
    const whid = parseInt(
      localStorage.getItem(LS_DEFAULT_WAREHOUSE_KEY) || '-1'
    );
    //console.log('warehosue', data);

    if (whid > 0) {
      const exist = data.filter((e) => e.id === whid);

      if (exist.length === 0) {
        localStorage.removeItem(LS_DEFAULT_WAREHOUSE_CODE);
        localStorage.removeItem(LS_DEFAULT_WAREHOUSE_KEY);
        localStorage.removeItem(LS_DEFAULT_WAREHOUSE_WAREHOUSEID);
        //localStorage.removeItem(LS_SHIPMENT_PRINTER_KEY);
        //localStorage.removeItem(LS_PACKING_PRINTER_KEY);
      }
    }
  };

  const onSelectProfile = (index: number) => {
    enterApp(index);
  };

  React.useEffect(() => {
    if (!profileLoaded) {
      try {
        fetchUserProfile();
        setProfileLoaded(true);
      } catch (e) {
        setProfileLoaded(true);
      }
    }

    if (!inited) {
      globalThis.__convertTimeByUtc__ = convertTimeByUtc;
      globalThis.__convertToUtcTime__ = convertToUtcTime;
      globalThis.__FULFILLMENT_VERSION__ = APP_VERSION;
      window.addEventListener('beforeunload', function (evt) {
        if (pageIsHeld) {
          evt.preventDefault();
          evt.returnValue = '';
        }
      });
      //testBusinessDay();

      if (isTestEntry) {
        const profileStr = localStorage.getItem(LS_TESTING_PROFILE_KEY) || '';
        const token = localStorage.getItem(LS_TESTING_TOKEN_KEY) || '';

        if (profileStr && token) {
          //console.log('profile', profileStr);
          //console.log('totken', token);
          try {
            const profile = JSON.parse(profileStr);

            tokenKeeper.apiToken = token;
            userProfiles.profiles = [profile];
            userProfiles.profileSettings = profileSettings;
            enterApp(0);
          } catch (e) {
            message.error(`Error: ${e}`);
          }
          localStorage.removeItem(LS_TESTING_PROFILE_KEY);
          localStorage.removeItem(LS_TESTING_TOKEN_KEY);
        }
      }

      //setInited(false);
      setInited(true);
    }

    if (userReady) {
      if (!cacheLoaded) {
        loadCache();
        setCacheLoaded(true);
      }
    }
  }, [
    cacheLoaded,
    enterApp,
    fetchUserProfile,
    loadCache,
    inited,
    isTestEntry,
    pageIsHeld,
    profileLoaded,
    userReady,
    profileSettings
  ]);

  const fetchUserProfileSettings = async () => {
    const res = await getUserProfileSettings();
    setProfileSettings(res);
  }

  useEffect(() => {
    if (profileSelected === true) {
      fetchUserProfileSettings();
    }
  }, [profileSelected,])

  return (
    <>
      {userReady ? (
        readyData ? (
          <Navigation />
        ) : (
          <ScreenMask backgroundColor="#FFF">
            <Loading size={LOADING_ICON_SIZE1}>
              {cacheError ? (
                <Row>
                  <Space>
                    <InfoButton size="large" onClick={() => window.location.reload()}>Refresh</InfoButton>
                    <Button size="large" type="primary" onClick={() => logout()}>Logout</Button>
                  </Space>
                </Row>
              ) : (
                <SyncOutlined spin />
              )}
            </Loading>
          </ScreenMask>
        )
      ) : (
        <ScreenMask backgroundColor={maskBgColor}>
          {profileLoaded ? (
            profileSelected ? (
              <Row align="middle" justify="center" style={{ height: '100%' }}>
                <Col span={8}>
                  <Alert message={alertMessage} type="warning" />
                </Col>
              </Row>
            ) : (
              <Row align="middle" justify="center" style={{ height: '100%' }}>
                {isTestEntry ? (
                  <CypressDoor />
                ) : (
                  <ProfileSelector
                    onChange={onSelectProfile}
                    profiles={profiles}
                  />
                )}
              </Row>
            )
          ) : (
            <Loading size={LOADING_ICON_SIZE1} />
          )}
        </ScreenMask>
      )}
    </>
  );
}

export default App;
