import axios, { CancelToken } from 'axios';
import qs from 'qs';
import { message } from 'components/common';
import {
  AXIOS_REQUEST_TIMEOUT,
  HTTP_STATUS_OK,
  SERVER_ERR_MSG_DEFAULT_DISPLAY_DURATION,
  SERVER_ERR_MSG_DEVELOPMENT_DISPLAY_DURATION,
} from 'constants/config';
import { logout, userProfiles } from 'utils/auth';
import { formatMessagewithBr } from 'utils'

const _digitBridgeApi = axios.create({
  baseURL: process.env.BASE_URL,
  timeout: AXIOS_REQUEST_TIMEOUT,
});

const _digitBridgePrinterApi = axios.create({
  baseURL: printerBaseUrl(),
  timeout: AXIOS_REQUEST_TIMEOUT,
});

const _digitbridgePrinterSimpleApi = axios.create({
  baseURL: printerBaseUrl(),
  timeout: AXIOS_REQUEST_TIMEOUT,
});

const _digitBridgeSimpleApi = axios.create({
  baseURL: process.env.BASE_URL,
  timeout: AXIOS_REQUEST_TIMEOUT,
});

const _erpApi = axios.create({
  baseURL: process.env.ERP_API_BASE_URL,
});

// const _intergrationApi = axios.create({
//   baseURL: process.env.INTERGRATION_BASE_URL,
// });

const _tokenKeeper = () => {
  let _apiToken = '';

  // eslint-disable-next-line
  let _apiTokenWriteCount = 0;

  return {
    get apiToken() {
      return _apiToken;
    },

    set apiToken(token) {
      _apiToken = token;
      // eslint-disable-next-line
      _apiTokenWriteCount += 0;
    },
  };
};

async function requestErpSuccess(config) {
  const params = erpAuthParams();

  config.headers = {
    ...(config.headers || {}),
    ...(userRoleHeaders() || {}),
  };
  config.params = {
    ...config.params,
    ...params,
  };

  return Promise.resolve(config);
}

// async function requestIntergrationSuccess(config) {
//   const params = intergrationAuthParams();

//   config.headers = {
//     ...(config.headers || {}),
//     ...(userRoleHeaders() || {}),
//   };
//   config.params = {
//     ...config.params,
//     ...params,
//   };

//   return Promise.resolve(config);
// }

async function requestPrinterSuccess(config) {
  const params = printerAuthParams();

  config.headers = {
    ...(config.headers || {}),
    ...(printerRoleHeaders() || {}),
  };
  config.params = {
    ...config.params,
    ...params,
  };

  return Promise.resolve(config);
}

async function requestSuccess(config) {
  const params = authParams();

  config.headers = {
    ...(config.headers || {}),
    ...(userRoleHeaders() || {}),
  };
  config.params = {
    ...config.params,
    ...params,
  };

  return Promise.resolve(config);
}

function requestError(error) {
  throw error;
}

function responseSuccess(response) {
  return response.data;
}

function simpleResponseSuccess(response) {
  return response;
}

async function responseError(error) {
  const { response } = error;

  let duration = SERVER_ERR_MSG_DEFAULT_DISPLAY_DURATION;
  let errText = '';

  if ('development' === process.env.NODE_ENV) {
    duration = SERVER_ERR_MSG_DEVELOPMENT_DISPLAY_DURATION;
  }

  if (response && response.status !== HTTP_STATUS_OK) {
    errText = response.statusText;
    //console.log('eeeee', response);

    /*if ('development' !== process.env.NODE_ENV) {
      errText = 'Server request error';
    }*/
    if ([403].indexOf(response.status) > -1) {
      errText = 'No access to resources';
    }

    if ('object' === typeof response.data && response.data) {
      if (
        'string' === typeof response.data.message ||
        'string' === typeof response.data.Message ||
        'string' === typeof response.data.error
      ) {
        errText += `: ${response.data.message || response.data.Message || response.data.error || ''}`;
      } else if (response.data instanceof Blob) {
        try {
          const rawError = errText;
          errText = await response.data.text();
          const errObj = JSON.parse(errText);

          if (errObj && typeof errObj === 'object' &&
            (errObj.message || errObj.Message)
          ) {
            errText = `${rawError}: ${errObj.message || errObj.Message}`;
          }
        } catch (e) {
          console.log('error data is not readable', response.data);
        }
      }
    }

    if ([401].indexOf(response.status) > -1) {
      if (process.env.BASE_URL === response.config.baseURL) {
        logout();
      } else {
        console.log('res ->', response);
      }
    }
  } else if (error.isAxiosError) {
    const errorData = error.toJSON();
    const { name } = errorData;

    if (name && errorData.message) {
      errText = `${name}: ${errorData.message}`;
    }
  }

  if (errText) {
    const msgFn = response && typeof response === 'object' && [400].indexOf(response.status) > -1 ? message.info : message.error;

    if ('development' === process.env.NODE_ENV) {
      msgFn({
        content: formatMessagewithBr(errText),
        duration,
      });
    } else {
      msgFn({content: formatMessagewithBr(errText)});
    }
  }

  throw error;
}

_digitBridgeApi.interceptors.request.use(requestSuccess, requestError);
_digitBridgeApi.interceptors.response.use(responseSuccess, responseError);
_digitBridgePrinterApi.interceptors.request.use(
  requestPrinterSuccess,
  requestError
);
_digitBridgePrinterApi.interceptors.response.use(
  responseSuccess,
  responseError
);
_digitBridgeSimpleApi.interceptors.request.use(requestSuccess, requestError);

_digitbridgePrinterSimpleApi.interceptors.request.use(requestPrinterSuccess, requestError);
_digitbridgePrinterSimpleApi.interceptors.response.use(simpleResponseSuccess, responseError);

_erpApi.interceptors.request.use(requestErpSuccess, requestError);
_erpApi.interceptors.response.use(responseSuccess, responseError);

// _intergrationApi.interceptors.request.use(requestIntergrationSuccess, requestError);
// _intergrationApi.interceptors.response.use(responseSuccess, responseError);
/**
 * Do not use this method, this method is only for testing.
 */
export async function getByFetch(path, cfg) {
  const { baseURL } = _digitBridgeApi.defaults;
  const backdoor = authParams();

  if (!cfg || 'object' !== typeof cfg) {
    cfg = {};
  }

  const headers = {
    ...(cfg.headers || {}),
    ...(userRoleHeaders() || {}),
  };
  const params = {
    ...cfg.params,
    ...backdoor,
  };
  const url = `${baseURL}/${path}?${qs.stringify(params)}`;
  /*
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url);
  for (let k in headers) {
    xhr.setRequestHeader(k, headers[k]);
  }
  xhr.send(JSON.stringify(cfg.data));
  */
  return await fetch(url, {
    method: 'GET',
    //body: JSON.stringify(cfg.data) || null,
    headers,
  });
}

export function authParams() {
  //const { profile } = userProfiles;
  /**
   * This code will be removed when the service is ready
   * Currently we don't have a way to get the code
   */

  return {
    //BackdoorModeEmail: 'wenchao.ling@hurraymart.com',
    //BackdoorModeEmail: profile.email,
    //BackdoorModePassword: 'blabla',
    //code: 'YvwwwITU12X7R/Ff6RDomh3V/Zw9QoamFbyIAqZoQgPC0qCwWC/fqQ==',
    code: process.env.CODE,
  };
}

export function erpAuthParams() {
  //const { profile } = userProfiles;
  /**
   * This code will be removed when the service is ready
   * Currently we don't have a way to get the code
   */

  return {
    //BackdoorModeEmail: 'wenchao.ling@hurraymart.com',
    //BackdoorModeEmail: profile.email,
    //BackdoorModePassword: 'blabla',
    //code: 'YvwwwITU12X7R/Ff6RDomh3V/Zw9QoamFbyIAqZoQgPC0qCwWC/fqQ==',
    code: process.env.ERP_API_CODE,
  };
}

export function intergrationAuthParams() {
  return {
    code: process.env.INTERGRATION_CODE,
  };
}

function printerAuthParams() {
  /**
   * This code will be removed when the service is ready
   * Currently we don't have a way to get the code
   */
  const code = (function() {
    return process.env.PRINTER_CODE;
  })();

  //console.log('code -> ->', code);
  return {
    //BackdoorModeEmail: "wenchao.ling@hurraymart.com",
    //BackdoorModePassword: "blabla",
    code,
    clientId: 'default',
  };
}

/**
 * Get the list content in the response data.
 */
export function getDataListInResponse(res) {
  let ret = [];

  if (res && 'object' === typeof res) {
    const { code, data } = res;

    if (code === HTTP_STATUS_OK && Array.isArray(data)) {
      ret = data;
    }
  }

  return ret;
}

/**
 * Generate a new cancel token.
 */
export function newCancelToken() {
  return CancelToken.source();
}

/**
 * Get printer API base url.
 *
 * @return {string}
 */
export function printerBaseUrl() {
  return process.env.PRINTER_BASE_URL;
}

/**
 * Return header that indicates the printer's role.
 *
 * @return {{[key: string]: string} | undefined}
 */
export function printerRoleHeaders() {
  const { profile } = userProfiles;

  switch (process.env.NODE_ENV) {
    case 'development':
    case 'staging':
    case 'production':
    case 'testing':
      return {
        Authorization: _tokenKeeperInMod.apiToken,
        masterAccountNum: profile.masterAccountNum,
        profileNum: profile.profileNum,
      };
    default:
      break;
  }

  return {};
}

/**
 * Return header that indicates the user's role.
 *
 * @return {{[key: string]: string} | undefined}
 */
export function userRoleHeaders() {
  const { profile } = userProfiles;
  // console.log('api Token', _tokenKeeperInMod.apiToken);
  switch (process.env.NODE_ENV) {
    case 'development':
    case 'staging':
    case 'production':
    case 'testing':
      return {
        Authorization: _tokenKeeperInMod.apiToken,
        masterAccountNum: profile.masterAccountNum,
        profileNum: profile.profileNum,
      };
    default:
      break;
  }

  return {};
}

const _tokenKeeperInMod = _tokenKeeper();

export const tokenKeeper = _tokenKeeperInMod;
export const digitBridgeApi = _digitBridgeApi;
export const digitBridgePrinterApi = _digitBridgePrinterApi;
export const digitBridgeSimpleApi = _digitBridgeSimpleApi;
export const digitbridgePrinterSimpleApi = _digitbridgePrinterSimpleApi
export const erpAPI = _erpApi;
// export const intergrationApi = _intergrationApi;