import React, { useCallback, useState } from 'react';
import InputDialog from './InputDialog';
import ResultDialog from './ResultDialog';
type Props = {
  lines: any[];
  PoId: string;
  locations: any[];
  onHide: () => void;
  onSuccess: (ls: any[]) => void;
  warehouseCode: string;
  lotEnable: boolean;
  uomEnable: boolean;
};
// eslint-disable-next-line
export default (props: Props) => {
  const [step, setStep] = useState<number>(1);
  const { onHide, onSuccess, PoId, warehouseCode } = props;
  const [items, setItems] = useState<any[]>([]);
  const [defaultStr, setDefaultStr] = useState<string>('');
  /**
   * handle scan to move submit event
   */
  const handleSubmit = useCallback((validData, str) => {
    setItems(validData);
    setDefaultStr(str);
    setStep(2);
  }, []);
  if (step === 1) {
    return (
      <InputDialog
        defaultStr={defaultStr}
        lines={props.lines}
        locations={props.locations}
        onSubmit={handleSubmit}
        onHide={onHide}
        lotEnable={props.lotEnable}
        uomEnable={props.uomEnable}
      />
    );
  } else if (step === 2 && items) {
    return (
      <ResultDialog
        warehouseCode={warehouseCode}
        PoId={PoId}
        lotEnable={props.lotEnable}
        uomEnable={props.uomEnable}
        lines={props.lines}
        items={items}
        onHide={(data) => (data ? onSuccess(data) : onHide())}
        reback={() => {
          setItems([]);
          setStep(1);
        }}
      />
    );
  }
  return null;
};
