import React from 'react';
import { Row, Table, TableProps } from 'antd';
import theme from 'assets/styles/theme';
import { SHIPMENT_ITEM_VERIFY_STATE } from 'constants/config';
import styled from 'styled-components';
import './NewShipment.css';

interface ShipmentTableProps extends TableProps<OrderShipmentItem> { }
export function ShipmentTable(props: ShipmentTableProps): JSX.Element {
  const { loading, columns, dataSource } = props;
  return (
    <Table
      loading={loading}
      rowClassName={(row: OrderShipmentItem, index) => {
        return row.verifyStatus === SHIPMENT_ITEM_VERIFY_STATE
          ? 'newshipment-verified'
          : index % 2 === 0
            ? 'antd-table-odd-row'
            : 'antd-table-even-row';
      }}
      style={{ marginTop: 20, height: 370 }}
      scroll={{ y: 330 }}
      columns={columns}
      dataSource={dataSource}
      rowKey="sku"
      pagination={false}
    />
  );
}

export const ShipmentWrapperContainer = styled.div`
  // height: calc(100% - 140px);
  width: 100%;
  overflow-y: auto;
  // position: absolute;

  & .move-shipment-selector .ant-select-clear {
    font-size: 18px;
    margin-right: 2px;
    margin-top: -9px;
  }

  & .move-shipment-selector .ant-select-selector {
    border-color: ${theme['@primary-color']};
  }

  & .move-shipment-selector.ant-select-disabled .ant-select-selector {
    border-color: #D9D9D9;
  }

  & .shipment-expand-icon-box,
    .shipment-option-icon-box {
    cursor: pointer;
    font-size: 14px;
    /*margin: 6px;*/
  }

  & .shipment-expand-icon-box {
    margin-left: 6px;
    margin-right: 0;
  }

  & .shipment-item-all-selected {
    background-color: ${theme['@warning-bg-color']};
  }

  & .shipment-option-icon-box {
    /*margin: 8px;*/
  }

  & .shipment-title {
    cursor: pointer;
    font-size: 16px;
    font-weight: 550;
  }

  & .shipment-title .bi-printer {
    color: ${theme['@info-color']};
  }

  & .shipment-title .bi-upc-scan {
    color: ${theme['@danger-color']};
  }
    
  & .shipment-toolbar {
    margin-bottom: 10px;
    padding: 0 10px;
  }

  & .shipment-wrapper {
    border: 1px solid #D9D9D9;
    border-radius: ${theme['@border-radius-base']};
    margin-bottom: 6px;
  }

  & .shipment-wrapper.focus {
    border: 1px solid ${theme['@primary-color']};
    box-shadow: 0 0 0 3px rgb(25 118 210 / 20%);
    outline: 0;
  }

  & .shipment-wrapper .ant-input-number-input {
    font-size: 22px;
  }

  & .shipment-wrapper .header-col-1 {
    width: calc(100% - 500px);
  }

  & .shipment-wrapper .wrapper-space {
    width: 100%;
  }

  & .shipment-wrapper .wrapper-space .ant-space-item:nth-child(2) {
    width: 180px;
  }

  & .shipment-wrapper .wrapper-space .ant-space-item:nth-child(3) {
    width: 156px;
  }

  & .shipment-wrapper .wrapper-space .ant-space-item:nth-child(4) {
    width: calc(100% - 372px);
  }
`;

export const SkuLabel = styled(Row)`
  display: inline-block;
  font-size: 1.4em !important;
  max-width: 260px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const SkuTitle = styled(Row)`
  font-weight: 600;
  font-size: 1.4em !important;
`;
export const TableHeader = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
export const Qty = styled(Row)`
  font-size: 46px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;