/**
 * shipping provider account service requests are here.
 */
import { digitBridgeApi } from './http';


export const getShippingProviderAccounts = async (): Promise<any> => {
    return await digitBridgeApi.get('/api/shippingProviderAccounts')
}

type AddShippingProviderAccountProps = {
    ShippingProviderAccountNum: number,
    ShipProviderType: number,
    Name: string,
    Account: string,
    AppKey: string,
    Attr01: string,
    Attr02: string,
  }

export const addShippingProviderAccount = async (params: AddShippingProviderAccountProps): Promise<any> => {
    const json = JSON.stringify(params);
    return await digitBridgeApi.post('/api/shippingProviderAccounts/add', json);
}

export const delShippingProviderAccount = async (shippingProviderAccountNum: number): Promise<any> => {
    return await digitBridgeApi.post('/api/shippingProviderAccounts/delete', '', { params: { shippingProviderAccountNum }});
}

type EditShippingProviderAccountProps = {
    ShippingProviderAccountNum: number,
    ShipProviderType: number,
    Name: string,
    Account: string,
    AppKey: string,
    Attr01: string,
    Attr02: string,
  }

export const editShippingProviderAccount = async (params: EditShippingProviderAccountProps): Promise<any> => {
    const json = JSON.stringify(params);
    return await digitBridgeApi.post('/api/shippingProviderAccounts/update', json);
}

export const getShippingProviderAccountsConfig = async (): Promise<any> => {
    return await digitBridgeApi.get('/api/shippingProviderAccounts/config')
}

export const getShippingProviderAccountsCarrierInfo = async (shippingProviderAccountNum: number, carrierType: number): Promise<any> => {
    return await digitBridgeApi.get('/api/shippingProviderAccounts/carrierAccounts', { params: {shippingProviderAccountNum, carrierType}})
}


export const getChannelfulfillmentAccounts = async (): Promise<any> => {
    return await digitBridgeApi.get('/api/channelfulfillment/account/list')
}

export const getChannelfulfillments = async (): Promise<any> => {
    return await digitBridgeApi.get('/api/channelfulfillment/list')
}

type AddChannelfulfillmentAccountProps = {
    ChannelFulfillmentAccountNum: number,
    ChannelFulfillmentNum: number,
    Name: string,
    // AppId: string,
    // AppSecret: string,
    // UseManagementIdentity: number,
    // TenantId: string,
    // OAuthScope: string,
    Sandbox: number,
    Note: string,
    Status: number,
  }

export const addChannelfulfillmentAccount = async (params: AddChannelfulfillmentAccountProps): Promise<any> => {
    const json = JSON.stringify(params);
    return await digitBridgeApi.post('/api/channelfulfillment/account/add', json);
}

export const delChannelfulfillmentAccount = async (channelFulfillmentAccountNum : number): Promise<any> => {
    return await digitBridgeApi.post('/api/channelfulfillment/account/delete', '', { params: { channelFulfillmentAccountNum  }});
}

type EditChannelfulfillmentAccountProps = {
    ChannelFulfillmentAccountNum: number,
    ChannelFulfillmentNum: number,
    Name: string,
    // AppId: string,
    // AppSecret: string,
    // UseManagementIdentity: number,
    // TenantId: string,
    // OAuthScope: string,
    Sandbox: number,
    Note: string,
    Status: number,
  }

export const editChannelfulfillmentAccount = async (params: EditChannelfulfillmentAccountProps): Promise<any> => {
    const json = JSON.stringify(params);
    return await digitBridgeApi.post('/api/channelfulfillment/account/update', json);
}
