import React, { useCallback, useEffect, useState } from 'react';
import { Button, Row, Space, Form, Spin, Col, Select } from 'antd';
import {
  adjustmentItems2,
  getProductLotList,
  getProductUomList,
} from 'services/product';
import { shallowEqual, useSelector } from 'react-redux';
import { RootState } from 'reducers';
import {
  ModalPlus as ModalDigitBridge,
  FormCancelButton,
  Locker,
  FormLabel,
  message,
  SelectProduct,
  fullProduct,
  InputInteger,
} from 'components/common';
import { getProfileSettingValue } from 'utils';
import { fetchLOTSettings } from 'services/lotSetting';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import GenerateLotDialog from '../../Po/PoReceive2/GenerateLotDialog';
import SelectLotDialog from 'components/Catalog/LotNumberView/SelectLotNumberDialog'

type Props = {
  warehouseNum: number;
  warehouseId: string;
  location: StringKAnyVPair;
  visible: boolean;
  onHide: () => void;
  onSuccess?: Function;
};

export default function AddItemDialog(props: Props) {
  const [skuProduct, setSkuProduct] = useState<ProductRow>();
  const [loading, setLoading] = useState(false);
  const transactionReasons = useSelector(
    (state: RootState) => state.admin.transactionReasons,
    shallowEqual
  );
  const [quantityLocked, setQuantityLocked] = useState<boolean>(false);
  const [snapshot, setSnapshot] = useState<StringKAnyVPair>({});
  const [lotList, setLotList] = useState<any[]>([]);
  const [uomList, setUomList] = useState<any[]>([]);
  const [lotEnable, setLotEnable] = useState(true);
  const [uomEnable, setUomEnable] = useState(true);
  const [selectedUom, setSelectedUom] = useState<any>();
  const [qty, setQty] = useState<number>(1);
  const { onHide, onSuccess, visible, location, warehouseId } = props;
  const [timeZone, setTimeZone] = useState<string>('');
  const [noUniqueControl, setNoUniqueControl] = useState(true);
  const [generateLotDialogVisible, setGenerateLotDialogVisible] =
    useState(false);
  const [lotNumberGenerationType, setLotNumberGenerationType] = useState<
    0 | 1 | 2
  >(0); // 0： all, 1: sys only, 2: user only
  const [selectLotDialogVisible, setSelectLotDialogVisible] = useState(false);
  const [form] = Form.useForm();

  const getLOTSettings = useCallback(async () => {
    try {
      const res = await fetchLOTSettings();
      if (res.isSuccess) {
        setLotNumberGenerationType(res.data.generation);
        setNoUniqueControl(!!res.data.unique);
      }
    } catch (error) { }
  }, []);

  const getSetting = useCallback(async () => {
    const res1 = await getProfileSettingValue('EnableLOT', '0');
    const res2 = await getProfileSettingValue('EnableUOM', '0');
    const res3 = await getProfileSettingValue('TimeZone');
    setLotEnable(res1 === '1');
    setUomEnable(res2 === '1');
    setTimeZone(res3);
    if (res1 === '1') {
      getLOTSettings();
    }
  }, [getLOTSettings]);

  React.useEffect(() => {
    getSetting();
  }, [getSetting]);

  useEffect(() => {
    setSnapshot({ quantity: 1 });
  }, []);
  /**
   * handle submit event
   */
  const handleSubmit = useCallback(async () => {
    try {
      await form.validateFields();
    } catch (e) {
      return;
    }
    if (!skuProduct) {
      return;
    }
    setLoading(true);
    // const quantity = form.getFieldValue('quantity');
    // const reason = form.getFieldValue('reason');
    const formValue = form.getFieldsValue();
    // let skuProduct = (await fullProduct(product)) as ProductRow;
    //judge product'id is empty
    if (!skuProduct) {
      setLoading(false);
      return;
    }
    const result = await adjustmentItems2({
      warehouseId: warehouseId,
      productNum: skuProduct.id,
      locationNum: location.id,
      quantity: formValue.quantity,
      transactionReason: formValue.reason,
      lotNumber: formValue.lotNumber,
      uom: formValue.uom || 'EA',
      UomRate: selectedUom?.uomRate || 1,
    });
    setLoading(false);
    if (result) {
      message.success({ content: 'Added Item Successfully!' });
      setQty(1)
    }
    form.resetFields();
    if (quantityLocked) {
      form.setFieldsValue({ quantity: formValue.quantity });
    }

    if ('function' === typeof onSuccess) {
      onSuccess();
    }
    // onHide();
  }, [
    form,
    location,
    onSuccess,
    quantityLocked,
    warehouseId,
    skuProduct,
    selectedUom,
  ]);
  /**
   * handle quantity change event
   */
  const handleQuantityChange = useCallback(
    (value: string) => {
      form.setFieldsValue({
        quantity: parseInt(value),
      });
      setQty(parseInt(value));
    },
    [form]
  );

  const getUomList = useCallback(async () => {
    const res = await getProductUomList(skuProduct?.sku || '');
    if (res.isSuccess) {
      const temp = res.data.filter((i: any) => i.uom === 'EA')
      if (temp.length > 0) {
        setSelectedUom(temp[0])
        form.setFieldsValue({
          uom: temp[0].uom
        })
      }
      setUomList(res.data);
    }
  }, [skuProduct, form]);

  const getLotList = useCallback(async () => {
    const res = await getProductLotList(skuProduct?.productId || '');
    if (res.isSuccess) {
      setLotList(res.data);
    }
  }, [skuProduct]);
  /**
   * handle select product event
   */
  const handleSelectProductChange = useCallback(
    async (_: string, product: ProductRow) => {
      form.setFieldsValue({ identifier: product.sku });
      if (!product.productId) {
        const res = (await fullProduct(product)) as ProductRow;
        setSkuProduct((prev) => {
          return { ...prev, ...res };
        });
      } else {
        // setSkuProduct(product);
        setSkuProduct((prev) => {
          return { ...prev, ...product };
        });
      }
    },
    [form]
  );

  useEffect(() => {
    if (uomEnable && skuProduct && skuProduct.sku) {
      getUomList();
    }
    if (lotEnable && skuProduct && skuProduct.productId) {
      getLotList();
    }
  }, [skuProduct, uomEnable, lotEnable, getLotList, getUomList]);
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  return (
    <ModalDigitBridge
      title="Add Item"
      centered
      visible={visible}
      width={650}
      destroyOnClose={true}
      getContainer={false}
      forceRender={true}
      form={form}
      keyboard={false}
      snapshot={snapshot}
      onClose={() => {
        onHide();
      }}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              onClick={handleSubmit}
              icon={<PlusOutlined />}
            >
              Add
            </Button>
            <FormCancelButton
              currentform={form}
              snapshot={snapshot}
              onClick={() => {
                onHide();
              }}
            />
          </Space>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <Form {...layout} form={form}>
          <Form.Item
            name="identifier"
            label={<FormLabel>Identifier</FormLabel>}
            rules={[{ required: true, message: 'Please input Identifier!' }]}
          >
            <SelectProduct
              noControl
              width={180}
              onChange={handleSelectProductChange}
              onBlurAble
            />
          </Form.Item>
          {skuProduct && lotEnable && (
            <Form.Item
              name="lotNumber"
              rules={[
                {
                  required: skuProduct.lotControl === 1,
                  message: 'Please select LOT#!',
                },
              ]}
              label={<FormLabel>LOT#</FormLabel>}
            >
              <Space>
                <Form.Item noStyle name="lotNumber">
                  <Select
                    allowClear
                    showSearch
                    style={{ width: 200, marginLeft: 4 }}
                    optionFilterProp="label"
                  >
                    {lotList.map((i) => (
                      <Select.Option
                        key={i.productLotNum}
                        value={i.lotNumber}
                        label={i.lotNumber}
                      >
                        {i.lotNumber}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Button icon={<SearchOutlined />} onClick={()=>{
                setSelectLotDialogVisible(true)}}></Button>
                <Button onClick={() => setGenerateLotDialogVisible(true)}>
                  Generate LOT#
                </Button>
              </Space>
            </Form.Item>
          )}
          {skuProduct && uomEnable && (
            <Form.Item name="uom" label={<FormLabel>UOM</FormLabel>}>
              <Select
                style={{ width: 200, marginLeft: 4 }}
                onChange={(value) => {
                  if (value) {
                    const temp = uomList.filter((i) => i.uom === value);
                    setSelectedUom(temp[0]);
                  } else {
                    setSelectedUom(undefined);
                  }
                }}
              >
                {uomList.map((i) => (
                  <Select.Option key={i.productUomNum} value={i.uom}>
                    {i.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {skuProduct && uomEnable && (
            <Form.Item label={<FormLabel>Rate</FormLabel>}>
              {selectedUom?.uomRate}
            </Form.Item>
          )}
          <Row align="middle" style={{ marginTop: -20 }}>
            <Col span={4} />
            <Col span={12}>
              <Form.Item
                style={{ marginTop: 20 }}
                name="quantity"
                initialValue="1"
                label={<FormLabel>Quantity</FormLabel>}
                rules={[{ required: true, message: 'Please input Quantity!' }]}
              >
                <InputInteger
                  type={2}
                  width={120}
                  onChange={handleQuantityChange}
                />
              </Form.Item>
            </Col>
            <Col span={1}></Col>
            <Col span={7}>
              <Locker
                type="checkbox"
                value={quantityLocked}
                onChange={setQuantityLocked}
                label="Keep Quantity"
              />
            </Col>
          </Row>
          {skuProduct && uomEnable && (
            <Form.Item label={<FormLabel>Base Qty</FormLabel>}>
              {(selectedUom?.uomRate || 1) * qty}
            </Form.Item>
          )}
          <Form.Item name="reason" label={<FormLabel>Reason</FormLabel>}>
            <Select style={{ width: 180 }}>
              {transactionReasons
                .filter((i) => i.type === 3)
                .map((item) => (
                  <Select.Option key={item.id} value={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Spin>
      {generateLotDialogVisible && (
        <GenerateLotDialog
          onClose={() => setGenerateLotDialogVisible(false)}
          onSave={(data: { [key: string]: any }) => {
            setGenerateLotDialogVisible(false);
            getLotList();
          }}
          noUniqueControl={noUniqueControl}
          rows={[{ ...skuProduct }]}
          generate={lotNumberGenerationType === 1}
          timeZone={timeZone}
          lotNumberGenerationType={lotNumberGenerationType}
          visible
        />
      )}
      {selectLotDialogVisible && skuProduct?.sku && (
        <SelectLotDialog 
          sku={skuProduct.sku}
          onClose={()=>{setSelectLotDialogVisible(false)}}
          onSelect={(lotNumber)=> {
            form.setFieldsValue({lotNumber})
            setSelectLotDialogVisible(false)
          }}
          visible
        />
      )}
    </ModalDigitBridge>
  );
}
