import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Row,
  Space,
  Typography,
  Form,
  Input,
  Table,
  Divider,
} from 'antd';
import { leaveEditForm } from 'utils';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import type { ColumnsType } from 'antd/es/table';
import { getBatchProductUomList } from 'services/product';

const { Text } = Typography;
type DataItem = {
  id: string;
  sku: string;
  title?: string;
  productId: string;
  receivingQty: number;
  receivingLocationCode: string;
  receivingLocationNum: number;
  validsku: boolean;
  validLocation: boolean;
  validQty: boolean;
  lotNumber?: string;
  uom?: string;
  uomRate?: number;
  // validLotNumber?: boolean;
  validUom?: boolean;
};
type Props = {
  onHide: () => void;
  onSubmit: (items: DataItem[], str: string) => void;
  defaultStr: string;
  lines: any[];
  locations: any[];
  // lotUom: boolean;
  lotEnable: boolean;
  uomEnable: boolean;
};

// eslint-disable-next-line
export default (props: Props) => {
  const {
    onHide,
    onSubmit,
    lines,
    defaultStr,
    locations,
    lotEnable,
    uomEnable,
  } = props;
  const [data, setData] = useState<DataItem[]>([]);
  const [str, setStr] = useState(defaultStr);
  const [form] = Form.useForm();

  const batchFetchUoms = async (skus: string[]) => {
    let skuUom: any = {};
    try {
      const res = await getBatchProductUomList(skus);
      if (res.isSuccess) {
        res.data.map((i: any) => {
          skuUom[i.key] = i.uomList;
          return true;
        });
      }
    } catch (error) {
    } finally {
      return skuUom;
    }
  };

  const checkStrLotUom = useCallback(
    async (str: string) => {
      const temp = str.split('\n');
      let skus: string[] = [];
      temp.map((i) => {
        const t = i.split('\t');
        if (t.length > 0 && !skus.includes(t[0]) && t[0]) {
          skus.push(t[0]);
        }
        return true;
      });
      const uoms = await batchFetchUoms(skus);
      const tempArr: DataItem[] = [];
      temp.map((i, index) => {
        const [str1, str2, str3, str4, str5] = i.split('\t');
        if (str1 && str2 && str3) {
          const skuArr = lines.filter((k) => k.sku === str1 || k.upc === str1);
          const locationArr = locations.filter((j) => j.locationCode === str3);
          const isInvalidNumber =
            Number(str2) > 0 && Number.isInteger(Number(str2));
          const uomInfo = (uoms[str1] || []).filter((h: any) => h.uom === str5);
          tempArr.push({
            id: `row${index}`,
            sku: skuArr.length > 0 ? skuArr[0].sku : str1,
            productId: skuArr.length ? skuArr[0].productId : '0-0',
            title: skuArr.length > 0 ? skuArr[0].title : '',
            receivingQty: isInvalidNumber ? Number(str2) : NaN,
            receivingLocationCode: str3,
            receivingLocationNum:
              locationArr.length > 0 ? locationArr[0].locationNum : 0,
            validsku: skuArr.length > 0,
            validLocation: locationArr.length > 0,
            validQty: isInvalidNumber,
            lotNumber: str4,
            uom: str5,
            validUom: uomInfo.length > 0,
            uomRate: uomInfo.length > 0 ? uomInfo[0].uomRate : undefined,
          });
        }
        return true;
      });
      setData(tempArr);
    },
    [lines, locations]
  );

  const checkStrLot = useCallback(
    async (str: string) => {
      const temp = str.split('\n');
      // let skus: string[] = [];
      // temp.map((i=> {
      //   const t = i.split('\t');
      //   if(t.length >0 && !skus.includes(t[0]) && t[0]){
      //     skus.push(t[0])
      //   }
      //   return true
      // }))
      // const uoms = await batchFetchUoms(skus)
      const tempArr: DataItem[] = [];
      temp.map((i, index) => {
        const [str1, str2, str3, str4] = i.split('\t');
        if (str1 && str2 && str3) {
          const skuArr = lines.filter((k) => k.sku === str1 || k.upc === str1);
          const locationArr = locations.filter((j) => j.locationCode === str3);
          const isInvalidNumber =
            Number(str2) > 0 && Number.isInteger(Number(str2));
          // const uomInfo = (uoms[str1] || []).filter((h:any)=> h.uom === str5)
          tempArr.push({
            id: `row${index}`,
            sku: skuArr.length > 0 ? skuArr[0].sku : str1,
            productId: skuArr.length ? skuArr[0].productId : '0-0',
            title: skuArr.length > 0 ? skuArr[0].title : '',
            receivingQty: isInvalidNumber ? Number(str2) : NaN,
            receivingLocationCode: str3,
            receivingLocationNum:
              locationArr.length > 0 ? locationArr[0].locationNum : 0,
            validsku: skuArr.length > 0,
            validLocation: locationArr.length > 0,
            validQty: isInvalidNumber,
            lotNumber: str4,
            // uom: str5,
            // validUom: uomInfo.length > 0,
            // uomRate: uomInfo.length > 0 ? uomInfo[0].uomRate : undefined
          });
        }
        return true;
      });
      setData(tempArr);
    },
    [lines, locations]
  );

  const checkStrUom = useCallback(
    async (str: string) => {
      const temp = str.split('\n');
      let skus: string[] = [];
      temp.map((i) => {
        const t = i.split('\t');
        if (t.length > 0 && !skus.includes(t[0]) && t[0]) {
          skus.push(t[0]);
        }
        return true;
      });
      const uoms = await batchFetchUoms(skus);
      const tempArr: DataItem[] = [];
      temp.map((i, index) => {
        const [str1, str2, str3, str4] = i.split('\t');
        if (str1 && str2 && str3) {
          const skuArr = lines.filter((k) => k.sku === str1 || k.upc === str1);
          const locationArr = locations.filter((j) => j.locationCode === str3);
          const isInvalidNumber =
            Number(str2) > 0 && Number.isInteger(Number(str2));
          const uomInfo = (uoms[str1] || []).filter((h: any) => h.uom === str4);
          tempArr.push({
            id: `row${index}`,
            sku: skuArr.length > 0 ? skuArr[0].sku : str1,
            productId: skuArr.length ? skuArr[0].productId : '0-0',
            title: skuArr.length > 0 ? skuArr[0].title : '',
            receivingQty: isInvalidNumber ? Number(str2) : NaN,
            receivingLocationCode: str3,
            receivingLocationNum:
              locationArr.length > 0 ? locationArr[0].locationNum : 0,
            validsku: skuArr.length > 0,
            validLocation: locationArr.length > 0,
            validQty: isInvalidNumber,
            // lotNumber: str4,
            uom: str4,
            validUom: uomInfo.length > 0,
            uomRate: uomInfo.length > 0 ? uomInfo[0].uomRate : undefined,
          });
        }
        return true;
      });
      setData(tempArr);
    },
    [lines, locations]
  );

  const checkStr = useCallback(
    (str: string) => {
      const temp = str.split('\n');
      const tempArr: DataItem[] = [];
      temp.map((i, index) => {
        const [str1, str2, str3] = i.split('\t');
        if (str1 && str2 && str3) {
          const skuArr = lines.filter((k) => k.sku === str1 || k.upc === str1);
          const locationArr = locations.filter((j) => j.locationCode === str3);
          const isInvalidNumber =
            Number(str2) > 0 && Number.isInteger(Number(str2));
          tempArr.push({
            id: `row${index}`,
            sku: skuArr.length > 0 ? skuArr[0].sku : str1,
            productId: skuArr.length ? skuArr[0].productId : '0-0',
            title: skuArr.length > 0 ? skuArr[0].title : '',
            receivingQty: isInvalidNumber ? Number(str2) : NaN,
            receivingLocationCode: str3,
            receivingLocationNum:
              locationArr.length > 0 ? locationArr[0].locationNum : 0,
            validsku: skuArr.length > 0,
            validLocation: locationArr.length > 0,
            validQty: isInvalidNumber,
            // lotNumber: str4,
            // uom: str5,
          });
        }
        return true;
      });
      setData(tempArr);
    },
    [lines, locations]
  );

  const checkLotUom = useCallback(
    (str: string) => {
      if (lotEnable) {
        if (uomEnable) {
          checkStrLotUom(str);
        } else {
          checkStrLot(str);
        }
      } else {
        if (uomEnable) {
          checkStrUom(str);
        } else {
          checkStr(str);
        }
      }
    },
    [uomEnable, lotEnable, checkStrLotUom, checkStrLot, checkStrUom, checkStr]
  );

  const getId = useCallback(
    (k: DataItem) => {
      if (lotEnable) {
        if (uomEnable) {
          return `${k.sku}_${k.receivingLocationCode}_${k.lotNumber}_${k.uom}`;
        } else {
          return `${k.sku}_${k.receivingLocationCode}_${k.lotNumber}`;
        }
      } else {
        if (uomEnable) {
          return `${k.sku}_${k.receivingLocationCode}_${k.uom}`;
        } else {
          return `${k.sku}_${k.receivingLocationCode}`;
        }
      }
    },
    [lotEnable, uomEnable]
  );

  const getTempValidData = useCallback(
    (data: DataItem[]) => {
      if (uomEnable) {
        return data.filter(
          (i) => i.validsku && i.validLocation && i.validQty && i.validUom
        );
      } else {
        return data.filter((i) => i.validsku && i.validLocation && i.validQty);
      }
    },
    [uomEnable]
  );

  /**
   * handle submit event
   */
  const handleSubmit = useCallback(async () => {
    const validData: DataItem[] = [];

    getTempValidData(data).map((k) => {
      const id = getId(k);
      const index = validData.findIndex((l) => l.id === id);
      if (index > -1) {
        validData[index] = {
          ...validData[index],
          receivingQty: validData[index].receivingQty + k.receivingQty,
        };
      } else {
        validData.push({
          ...k,
          id,
        });
      }
      return true;
    });
    onSubmit(validData, str);
  }, [onSubmit, data, str, getId, getTempValidData]);
  /**
   * handle cancel event
   */
  const handleCancel = useCallback(async () => {
    const cancel = await leaveEditForm({}, form);
    if (cancel) {
      onHide();
    }
  }, [onHide, form]);

  useEffect(() => {
    if (defaultStr) {
      checkLotUom(defaultStr);
    }
  }, [defaultStr, checkLotUom]);

  const getColumns = useCallback(() => {
    const columns: ColumnsType<any> = [
      {
        title: 'SKU / UPC',
        dataIndex: 'sku',
        key: 'sku',
        render: (text, record) =>
          record.validsku ? (
            <Text>{text}</Text>
          ) : (
            <Text style={{ color: '#ff4d4f' }} delete>
              {text}
            </Text>
          ),
      },
      {
        title: 'Receiving Qty',
        dataIndex: 'receivingQty',
        key: 'qty',
        render: (text, record) =>
          record.validQty ? (
            <Text>{text}</Text>
          ) : (
            <Text style={{ color: '#ff4d4f' }} delete>
              {text}
            </Text>
          ),
      },
      {
        title: 'Receipt Location',
        dataIndex: 'receivingLocationCode',
        key: 'loacation',
        render: (text, record) =>
          record.validLocation ? (
            <Text>{text}</Text>
          ) : (
            <Text style={{ color: '#ff4d4f' }} delete>
              {text}
            </Text>
          ),
      },
      {
        title: 'LOT#',
        dataIndex: 'lotNumber',
        key: 'lotNumber',
        render: (text) => <Text>{text}</Text>,
      },
      {
        title: 'Receiving UOM',
        dataIndex: 'uom',
        key: 'uom',
        render: (text, record) =>
          record.validUom ? (
            <Text>{text}</Text>
          ) : (
            <Text style={{ color: '#ff4d4f' }} delete>
              {text}
            </Text>
          ),
      },
      {
        title: 'Receiving Rate',
        dataIndex: 'uomRate',
        key: 'uomRate',
        render: (text) => <Text>{text}</Text>,
      },
    ];
    let temp = [...columns];
    temp = lotEnable ? temp : temp.filter((i) => i.key !== 'lotNumber');
    temp = uomEnable
      ? temp
      : temp.filter((i) => i.key !== 'uomRate' && i.key !== 'uom');
    return temp;
  }, [lotEnable, uomEnable]);

  return (
    <Modal
      title="Copy From Excel"
      centered
      visible={true}
      width={800}
      destroyOnClose={true}
      onCancel={handleCancel}
      footer={
        <Row justify="end">
          <Space>
            <Button
              key="submit"
              type="primary"
              disabled={data.length === 0}
              onClick={handleSubmit}
              icon={<CheckOutlined />}
            >
              Continue
            </Button>
            <Button onClick={handleCancel} icon={<CloseOutlined />}>
              Cancel
            </Button>
          </Space>
        </Row>
      }
    >
      <Row style={{ marginBottom: 8 }}>
        <Row>
          <Space split={<Divider type="vertical" />}>
            <Typography.Text>SKU / UPC</Typography.Text>
            <Typography.Text>Receiving Qty</Typography.Text>
            <Typography.Text>Receipt Location</Typography.Text>
            {lotEnable && <Typography.Text>LOT#</Typography.Text>}
            {uomEnable && <Typography.Text>Receiving UOM</Typography.Text>}
          </Space>
        </Row>

        <Space align="center">
          <Input.TextArea
            style={{ width: 550 }}
            placeholder="Please paste the excel cells content"
            rows={16}
            value={str}
            onChange={(e) => setStr(e.target.value)}
          />
          <Button
            type="primary"
            disabled={str.length === 0}
            onClick={() => {
              checkLotUom(str);
            }}
          >
            Process
          </Button>
        </Space>
      </Row>
      <Table
        rowKey="id"
        scroll={{ y: 322 }}
        dataSource={data}
        columns={getColumns()}
        pagination={false}
      />
    </Modal>
  );
};
