import axios from 'axios';
import authentication from 'react-azure-b2c';
import { logout as performLogout } from 'services/user';
import { message } from 'components/common';
import { LS_PROFILE_FINGERPRINT_KEY } from 'constants/config';
import { fetchProfileSettings } from 'services/user';
import { ENTRANCE_PORTAL, getEntrance, logoutFromPortal } from './authenticate';


const _profileKeeper = () => {
  /**
   * Current profile index.
   */
  let profileIndex = 0;

  let profiles = [];

  let profileSettings = [];

  return {
    get profile() {
      return profiles[profileIndex];
    },

    get profiles() {
      return profiles;
    },

    get profileIndex() {
      return profileIndex;
    },

    get profileSettings() {
      return profileSettings;
    },

    set profileIndex(index) {
      profileIndex = index;
    },

    set profiles(list) {
      profiles = list;
    },

    set profileSettings(settings) {
      profileSettings = settings;
    },

    /**
     * Get profile by the index of the profile array.
     */
    getProfile(index) {
      return profiles[index];
    },

    getProfileFingerprint() {
      const str = localStorage.getItem(LS_PROFILE_FINGERPRINT_KEY);

      if (str && typeof str === 'string') {
        try {
          const p = JSON.parse(str);

          if (p && typeof p === 'object') {
            return p;
          }
        } catch (e) {
        }
      }
    },

    removeProfileFingerprint() {
      localStorage.removeItem(LS_PROFILE_FINGERPRINT_KEY);
    },

    saveCurrentProfileToBrowser() {
      const profile = profiles[profileIndex];

      if (profile && typeof profile === 'object') {
        if (
          typeof profile.masterAccountNum === 'number' &&
          typeof profile.profileNum === 'number'
        ) {
          // maybe masterAccountNum & profileNum is a comination unique key
          localStorage.setItem(LS_PROFILE_FINGERPRINT_KEY, JSON.stringify({
            masterAccountNum: profile.masterAccountNum,
            profileNum: profile.profileNum,
          }));
        }
      }
    },

    getProfileSettingValue(key) {
      if (profileSettings.length > 0) {
        const ps = profileSettings.filter(e => e.settingCode === key);

        if (ps.length > 0) {
          return ps[0].settingValue;
        }
      }

      return undefined;
    }
  };
};

export const config = {
  //instance: 'https://login.microsoftonline.com/tfp/',
  //var instance = 'https://' + tenantSubdomain + '.b2clogin.com/tfp/';
  //var instance = 'https://' + tenantSubdomain + '.b2clogin.cn/tfp/';
  instance: process.env.TFP_INSTANCE,
  tenant: process.env.TENANT,
  signInPolicy: process.env.SIGNIN_POLICY,
  clientId: process.env.CLIENT_ID,
  cacheLocation: 'localStorage',
  scopes: [process.env.CLIENT_ID],
  redirectUri: process.env.REDIRECT_URI,
  //redirectUri: 'https://fulfillmentweb.z4.web.core.chinacloudapi.cn',
};

export const getUserProfile = async (token) => {
  let profile = null;

  try {
    const { data } = await axios.post('/api/Profiles', null, {
      baseURL: process.env.BASE_URL,
      params: {
        code: process.env.CODE,
      },
      headers: {
        Authorization: token,
        //BackdoorModeEmail: 'wenchao.ling@hurraymart.com',
        //BackdoorModePassword: 'blabla',
      },
    });

    if (data) {
      const { isSuccess } = data;

      if (isSuccess) {
        profile = data.data;

        if (Array.isArray(profile)) {
          profilesInMod.profiles = profile;
        }
      }
    }
  } catch (e) {
    message.error(`Profile ${e}`);
  }

  return profile;
};

export const getUserProfileSettings = async () => {
  let ps = [];
  try {
    const settings = await fetchProfileSettings();

    if (Array.isArray(settings)) {
      profilesInMod.profileSettings = settings;
      ps = settings;
    }
  } catch (e) {
    message.error(`Fetch profile setting error: ${e}`);
  }
  return ps;
}

//export const isLoggingOut = false;

export const logout = async () => {

  const entrance = getEntrance();

  if (entrance === ENTRANCE_PORTAL) {
    logoutFromPortal();
    window.close();
  }

  //isLoggingOut = true;
  await performLogout();
  authentication.signOut();
  //store.dispatch(profileActions.setIsProfileSelected(false));
};

const profilesInMod = _profileKeeper();

export const userProfiles = profilesInMod;

export default authentication;
